import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  SwipeableDrawer,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import { ArrowBack, ArrowDropDownCircleOutlined, Label } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import SwitchMyProfilesBox from "pages/UserProfile/Sections/SwitchMyProfilesBox";
import MKAvatar from "components/MKAvatar";
import Friendship from "pages/UserProfile/Components/Friendship/Friendship";
import { isMobile } from "react-device-detect";
import MKButton from "components/MKButton";
import TruncateMarkup from "react-truncate-markup";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function MySideBarContent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [friendshipTab, setFriendshipTab] = React.useState("followers");
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin", "tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [currentData, updateCurrentUser] = useCurrentUser();
  const {
    isUserLoggedIn,
    myFollowings,
    myProfiles,
    myCurrentProfile,
    myCurrentUserLoading,
    myCurrentProfileLoading,
  } = currentData;
  const sideBarContent = [
    {
      id: 1,
      title: "Job Deals",
      items: [
        {
          title: "Contracts",
          link: "/profile/jobs/contracts",
          icon: "work_history",
        }, 
        {
          title: "Proposals",
          link: "/profile/jobs/proposals",
          icon: "event_note",
        },
      ],
    },
    // { id:2,
    //   title: "Contact",
    //   items: [
    //     {
    //       title: "Friends",
    //       link: "/pages/support/contact-us",
    //       icon: "contact_mail",
    //     },
    //     // {
    //     //   title:"About Us",
    //     //   link:"",
    //     //   icon:""
    //     // },
    //     {
    //       title: "Suggested Profiles",
    //       link: "/pages/AskQuestion",
    //       icon: "question_answer",
    //     },
    //   ],
    // },
  ];

  const sideBarContentCreator = [
    // {
    //   id: 1,
    //   title: "Baally Creator",
    //   items: [
    //     {
    //       title: "Creator tool",
    //       link: "https://creator.baally.com",
    //       target: "external",
    //       icon: "dashboard_customize",
    //     },
    //   ],
    // },
    // { id:2,
    //   title: "Contact",
    //   items: [
    //     {
    //       title: "Friends",
    //       link: "/pages/support/contact-us",
    //       icon: "contact_mail",
    //     },
    //     // {
    //     //   title:"About Us",
    //     //   link:"",
    //     //   icon:""
    //     // },
    //     {
    //       title: "Suggested Profiles",
    //       link: "/pages/AskQuestion",
    //       icon: "question_answer",
    //     },
    //   ],
    // },
  ];

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const truncateStringDefualt = (str, num, lableName) => {
    let res = str || "";
    try {
      if (str) {
        if (str.length > num) {
          let txt = str.slice(0, num) + "...";
          res = <>{txt} </>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };
  React.useEffect(() => {
    setDrawerOpened(false);
    setDrawer2Opened(false);
  }, [location.pathname]);

  React.useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myCurrentProfile, isUserLoggedIn]);

  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };

  const navigate = useNavigate();

  const drawer = (
    <div>
      {isUserLoggedIn ? (
        myCurrentProfile ? (
          <Grid item xs={12} mx="auto"  style={{
            // color: "rgba(0, 0, 0, 0.87)",
            // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            // boxShadow: "none",
            overflowY: "auto",
            // display: "flex",
            // flexDirection: "column",
            // flex:" 1 0 auto",
            // zIndex: "1200",
        position: isMobile?"relative": "fixed",
        // top: "0px",
        // outline: "0px",
        // left: "0px",
        // width: "15.625rem",
        // backgroundColor: "rgb(255, 255, 255)",
        height:  isMobile?"100%":"calc(-2rem + 100vh)",
            // margin: "1rem",
            // borderRadius: "0.75rem",
            // border: "none",
        }}>
            <Grid> 
              {myCurrentProfile.ProfileTypeId == 2 ? (
                <>
                  {(sideBarContentCreator
                    ? sideBarContentCreator.length
                      ? true
                      : false
                    : false) && (
                    <>
                      {sideBarContentCreator.map((sideBarTopic) => (
                        <List
                          subheader={
                            <ListSubheader sx={{ backgroundColor: "inherit" }}>
                              {sideBarTopic.title}
                            </ListSubheader>
                          }
                        >
                          {(sideBarTopic.items
                            ? sideBarTopic.items.length
                              ? true
                              : false
                            : false) &&
                            sideBarTopic.items.map((sideBarItem) => (
                              <ListItem
                                disablePadding
                                onClick={() => {
                                  if (sideBarItem.target == "external") {
                                    window.open(sideBarItem.link, "_blank");
                                  } else {
                                    goToPage(sideBarItem.link);
                                  }
                                }}
                              >
                                <ListItemButton>
                                  <ListItemIcon sx={{ minWidth: "30px" }}>
                                    {(
                                      sideBarItem.icon
                                        ? sideBarItem.icon != ""
                                          ? true
                                          : false
                                        : false
                                    ) ? (
                                      <Icon>{sideBarItem.icon}</Icon>
                                    ) : (
                                      <Label />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText
                                    secondary={
                                      <Typography
                                        sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                        variant="h6"
                                      >
                                        {sideBarItem.title}
                                      </Typography>
                                    }
                                  />
                                  {/* /pages/support/contact-us */}
                                </ListItemButton>
                              </ListItem>
                            ))}

                          <Divider sx={{ my: 1 }} variant="middle" />
                        </List>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              {(sideBarContent ? (sideBarContent.length ? true : false) : false) && (
                <>
                  {sideBarContent.map((sideBarTopic) => (
                    <List
                      subheader={
                        <ListSubheader sx={{ backgroundColor: "inherit" }}>
                          {sideBarTopic.title}
                        </ListSubheader>
                      }
                    >
                      {(sideBarTopic.items ? (sideBarTopic.items.length ? true : false) : false) &&
                        sideBarTopic.items.map((sideBarItem) => (
                          <ListItem
                            disablePadding
                            onClick={() => {
                              goToPage(sideBarItem.link);
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon sx={{ minWidth: "30px" }}>
                                {(
                                  sideBarItem.icon ? (sideBarItem.icon != "" ? true : false) : false
                                ) ? (
                                  <Icon>{sideBarItem.icon}</Icon>
                                ) : (
                                  <Label />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                secondary={
                                  <Typography
                                    sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                    variant="h6"
                                  >
                                    {sideBarItem.title}
                                  </Typography>
                                }
                              />
                              {/* /pages/support/contact-us */}
                            </ListItemButton>
                          </ListItem>
                        ))}

                      <Divider sx={{ my: 1 }} variant="middle" />
                    </List>
                  ))}
                </>
              )}

              <List
                subheader={
                  <ListSubheader sx={{ backgroundColor: "inherit" }}>{"Contacts"}</ListSubheader>
                }
              >
                <ListItem
                  disablePadding
                  onClick={() => {
                    let data = {
                      dialogId: "FriendshipDialogBox",
                      data: { profile: myCurrentProfile, friendshipTab: "followings" },
                    };
                    updateCurrentUser("currentGlobalDialogBoxData", data);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Icon>{"people"}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography variant="h6" sx={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          {"Following "}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    let data = {
                      dialogId: "FriendshipDialogBox",
                      data: { profile: myCurrentProfile, friendshipTab: "followers" },
                    };
                    updateCurrentUser("currentGlobalDialogBoxData", data);
                    //   setFriendshipTab("suggestions")
                    //  setDrawerOpened("freindship")
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Icon>{"people"}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography variant="h6" sx={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          {"Followers"}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  onClick={() => {
                    let data = {
                      dialogId: "FriendshipDialogBox",
                      data: { profile: myCurrentProfile, friendshipTab: "suggestions" },
                    };
                    updateCurrentUser("currentGlobalDialogBoxData", data);
                    //   setFriendshipTab("suggestions")
                    //  setDrawerOpened("freindship")
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <Icon>{"people"}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography variant="h6" sx={{ fontWeight: "500", fontSize: "0.9rem" }}>
                          {"Suggested Profiles"}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>

                <Divider sx={{ my: 1 }} variant="middle" />
              </List>

              {(myFollowings ? (myFollowings.length ? true : false) : false) && (
                <List
                  subheader={
                    <ListSubheader sx={{ backgroundColor: "inherit" }}>{"Friends"}</ListSubheader>
                  }
                >
                  {myFollowings.map(
                    (sideBarItem) =>
                      sideBarItem.Profile && (
                        <ListItem
                          disablePadding
                          onClick={() => {
                            goToPage("/" + sideBarItem.Profile.userName);
                          }}
                        >
                          <ListItemButton>
                            <ListItemIcon sx={{ minWidth: "30px" }}>
                              <MKAvatar size="xs"  
                              
                              src={ 
                                sideBarItem.Profile?.ProfileImages?.length?
                                ((CommonServices?.getPostAttachments({attachments:sideBarItem.Profile?.ProfileImages,thumbnail:true,firstUrl:true})))
                                   :sideBarItem.Profile?.profileImageUrl 
                            }/>
                            </ListItemIcon>
                            <ListItemText
                              secondary={
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "500", fontSize: "0.9rem" }}
                                >
                                  {" "}
                                  {CommonServices.getProfileName(sideBarItem.Profile)}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                  )}

                  <Divider sx={{ my: 1 }} variant="middle" />
                </List>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            {(myProfiles ? (myProfiles.length ? true : false) : false) && (
              <Grid container item xs={12} p={1} justifyContent="center">
                <Card sx={{ width: "100%" }}>
                  <Grid container alignItems="center">
                    <Grid p={1} item xs={12} lg={12}>
                      <MKBox pb={{ sm: 0, md: 1 }} px={{ sm: 2, md: 1 }}>
                        <List>
                          {/* <Divider/> */}
                          <ListItem
                            secondaryAction={
                              <IconButton
                                onClick={() => {
                                  let data = {
                                    dialogId: "SwitchProfileDialogBox",
                                    data: {},
                                  };
                                  updateCurrentUser("currentGlobalDialogBoxData", data);
                                  //   setFriendshipTab("suggestions")
                                  //  setDrawerOpened("freindship")
                                }}
                                edge="end"
                                aria-label="delete"
                              >
                                <ArrowDropDownCircleOutlined />
                              </IconButton>
                            }
                          >
                            {" "}
                            <ListItemAvatar sx={{ mr: 2 }}>
                              <Avatar
                                alt="Remy Sharp"
                                sx={{ width: 50, height: 50 }}
                                src={ 
                                  myCurrentProfile?.ProfileImages?.length?
                                  ((CommonServices?.getPostAttachments({attachments:myCurrentProfile?.ProfileImages,thumbnail:true,firstUrl:true})))
                                     :myCurrentProfile?.profileImageUrl 
                              }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              secondary={
                                myCurrentProfile
                                  ? truncateStringDefualt(myCurrentProfile.title, 21)
                                  : "Switch"
                              }
                              primary={
                                myCurrentProfile ? (
                                  <MKTypography
                                    component={Link}
                                    to={"/" + myCurrentProfile.userName}
                                    variant="h6"
                                    mb={1}
                                  >
                                    {" "}
                                    {myCurrentProfile
                                      ? truncateStringDefualt(
                                          CommonServices.getProfileName(myCurrentProfile),
                                          25
                                        )
                                      : "Profile"}
                                  </MKTypography>
                                ) : (
                                  "My Profiles"
                                )
                              }
                            />
                          </ListItem>
                        </List>
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
                <Divider sx={{ my: 1 }} variant="middle" />
              </Grid>
            )}
          </>
        )
      ) : (
        <>{<LogginOrRegisterSidebarComp />}</>
      )}
    </div>
  );

  function SideBarSkeleton() {
    return (
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <Skeleton width={"67%"} />
        <Skeleton variant="rectangular" width="67%">
          <div style={{ paddingTop: "27%" }} />
        </Skeleton>
        <Skeleton width={"67%"} />
        <Skeleton width={"67%"} animation="wave" />
        <Skeleton variant="rectangular" width="67%">
          <div style={{ paddingTop: "37%" }} />
        </Skeleton>
        <Skeleton width={"67%"} />
        <Skeleton width={"67%"} animation="wave" />
        <Skeleton width={"67%"} animation={false} />
      </Grid>
    );
  }

  function LogginOrRegisterSidebarComp() {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        sx={{ mt: 3 ,px:1}}
        alignItems="center"
      >
        <Grid item xs={12} xl={12} ml="auto">
          <MKTypography variant="h6" mb={1}>
            {"Are you looking for a job, product, house, car or other important things?"}
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={3}>
            Join this social network application to get everything you need at your fingertips.{" "}
          </MKTypography>
          {/* <MKTypography variant="body2" color="text" mb={3}>
                        {"Click the button below to view the instructions and accept the rules." }
                      </MKTypography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <MKButton
                variant="outlined"
                color="success"
                fullWidth
                onClick={() => {
                  UseAuth.goToAuthRegisterPage();
                }}
                sx={{ height: "100%" }}
              >
                {"Sign up"}
              </MKButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <MKButton
                onClick={() => {
                  UseAuth.goToAuthLoginPage();
                }}
                variant="outlined"
                color="success"
                fullWidth
                sx={{ height: "100%" }}
              >
                {"Login"}
              </MKButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box sx={{ flexGrow: 1, mb: isMobile ? 0 : 7 }}>
      {myCurrentProfileLoading || myCurrentUserLoading ? <SideBarSkeleton /> : drawer}

      {isMobile ? (
        <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={drawer2Opened == "switchAgency"}
          onClose={() => setDrawer2Opened(false)}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SwitchMyProfilesBox onClose={() => setDrawer2Opened(false)} />
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={drawer2Opened == "switchAgency"}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => setDrawer2Opened(false)}
          fullScreen={isMobile ? true : false}
        >
          <DialogTitle>Profiles </DialogTitle>
          <DialogContent
            dividers={true}
            sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
          >
            <SwitchMyProfilesBox onClose={() => setDrawer2Opened(false)} />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>
      )}

      <Dialog
        scroll={"paper"}
        sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        maxWidth="xs"
        open={drawerOpened == "freindship"}
        onClose={() => setDrawerOpened(false)}
        fullScreen={isMobile ? true : false}
      >
        <Friendship
          profile={myCurrentProfile}
          friendshipTab={friendshipTab}
          onClose={() => {
            setDrawerOpened(false);
          }}
        />
      </Dialog>
      {forceRerender}
    </Box>
  );
}

MySideBarContent.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
