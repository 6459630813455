 
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
// Images
import imageCompression from "browser-image-compression";
import profilePicture from "assets/images/bruce-mars.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import Card from "@mui/material/Card";
import React, { useEffect } from "react";
import {
  AddCircle, 
  CameraEnhance, 
  Delete, 
  Edit, 
  Logout, 
  SaveAlt, 
  Send,
  Share,
} from "@mui/icons-material";
import MKInput from "components/MKInput";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import { isMobile } from "react-device-detect";
import SwitchMyProfilesBox from "./SwitchMyProfilesBox";
import { CommonApiServices } from "Api-Services/commonApiServices";
import TruncateMarkup from "react-truncate-markup";
import {   PhotoSlider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { DropzoneDialog } from "mui-file-dropzone";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LightGallery from 'lightgallery/react';  // Correct way to import React component
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css'; // Import default lightGallery styles
import 'lightgallery/css/lg-zoom.css';      // Import plugin styles
import 'lightgallery/css/lg-thumbnail.css'; // Import thumbnail stylesimport lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';
// import './style.css';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'photoswipe/dist/photoswipe.css';
import { useCallback, useRef, useState } from 'react';


  
function ProfileHeader(props) {
  const queryClient=useQueryClient() 
  const param = useParams();
  const navigate = useNavigate();
  const [currentData, updateCurrentUser] = useCurrentUser();
  const { myFriendships, myCurrentProfile, currentUser, jobs } = currentData;
  const [currentProfile, setCurrentProfile] = React.useState(null);
  const [openedProfilePictureDropZone, setOpenedProfilePictureDropZone] = React.useState(false); 
  const [forceRerender, setForceRerender] = React.useState(false);
  const [isThisMyProfile, setIsThisMyProfile] = React.useState(false);
  const [profileImagesSliderVisible, setProfileImagesSliderVisible] = React.useState(false);
  const [profileImagesSliderIndex, setProfileImagesSliderIndex] = React.useState(0);
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [newWorkExperianceData, setNewWorkExperianceData] = React.useState({
    description: "",
    expectingSalaryPerHour: null,
    expectingSalaryPerMonth: null,
    isAvailable: true,
    jobId: null,
    workExperianceInMonth: null,
    skillsOrSubjects: [],
  }); 

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const lightGallery = useRef(null);

  const lightboxRef = useRef(null);

  
  let {data:profileImages} = useQuery({
    queryKey: ["/api/profile/getbyusername/"+param?.userName+"/profileimages"],
    queryFn: async () => { 
      return await HttpService.getApiData("/api/profile/getbyusername/"+param?.userName+"/profileimages");
    },
    // initialData:props?.profile
  }); 
 
const convertallprofiletoattachment=()=>{
    HttpService.getApiData("/api/profile/convertallprofiletoattachment");
  }
  
  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#dynamic-gallery',
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });

    // Hide the built-in close button and register the menu icon
    // if((myCurrentProfile?.id==currentProfile?.id)){ 
        lightbox.on('uiRegister', () => {
          // Hide the close button 
          // Register MoreVertOutlined icon in the toolbar
          lightbox.pswp.ui.registerElement({
            name: 'menuIcon',
            order: 1,
            isButton: true,
            tagName: 'button',
            className: 'pswp__button pswp__button--menu',
            appendTo: 'bar',
            html: `
              <div id="mui-menu-icon" style="display: flex; align-items: center; color: white;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
                  <circle cx="12" cy="5" r="1.5"></circle>
                  <circle cx="12" cy="12" r="1.5"></circle>
                  <circle cx="12" cy="19" r="1.5"></circle>
                </svg>
              </div>`,
            onClick: (event, pswp) => {
              event.stopPropagation();  // Prevent lightbox from closing
              handleMenuClick(event);
            },
          });
        });
      // }

    lightbox.on('change', () => {
      setProfileImagesSliderIndex(lightbox.pswp.currIndex); // Track the current image index
    });

    lightbox.init();
    lightboxRef.current = lightbox;

    return () => {
      lightbox.destroy();
    };
  }, [param]);
 

    // Function to fetch images on button click
    const handleOpenGallery = async () => {
      // setLoading(true);
      try { 
        setTimeout(() => {
          if (lightboxRef.current) {
            lightboxRef.current.loadAndOpen(0); // Open at the first image
          }
        }, 100); // Small delay to ensure images are loaded
      } catch (error) {
        console.error("Error loading images", error);
      } finally {
        // setLoading(false);
      }
    };
  // Close the lightbox function
  const closeLightbox = () => {
    console.log('closeLightbox:' );
    try {
    if (lightboxRef.current && lightboxRef.current.pswp) {
      console.log('closeLightbox closed'); 
      lightboxRef.current.pswp.close(); 
      console.log('closeLightbox closed 2');
    }
  } catch (error) {
    console.log('closeLightbox error',error);
    
  }
  };

  // Handle deletion of an image
  const sendemailforeveryprofile = async () => {
      // handleCloseMenu();
      try {
      // let attId=profileImages[profileImagesSliderIndex]?.id
      // let data = {attachmentSource:"profileImage"}
      let deleteAttachmentRes= await HttpService.getService(`/api/profile/sendemailforeveryprofile`,{});
      // if(deleteAttachmentRes?.success){
      //   closeLightbox()
      //   updateCurrentUser("myCurrentProfile")
      //   await queryClient.invalidateQueries(["/api/profile/getbyusername/"+param?.userName+"/profileimages"]);
      //   setForceRerender(!forceRerender)
      // }
      // setProfileImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };


  // Handle deletion of an image
  const deleteSelectedProfileAttachment = async () => {
      handleCloseMenu();
      try {
      let attId=profileImages[profileImagesSliderIndex]?.id
      let data = {attachmentSource:"profileImage"}
      let deleteAttachmentRes= await HttpService.postService(`/api/attachment/delete/${attId}`, data,{});
      if(deleteAttachmentRes?.success){
        closeLightbox()
        updateCurrentUser("myCurrentProfile")
        await queryClient.invalidateQueries(["/api/profile/getbyusername/"+param?.userName+"/profileimages"]);
        setForceRerender(!forceRerender)
      }
      // setProfileImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

 

  useEffect(() => {
    // console.log("props currentProfile", props);
    // ReactGA.send({ hitType: "pageview", page: location.pathname, title: "JobSeeker Profile" });
    if (props.currentProfile) {
      setCurrentProfile(props.currentProfile);
    }
    //  if(props.isThisMyProfile){
    setIsThisMyProfile(props.isThisMyProfile);
    // }
    setForceRerender(!forceRerender);
  }, [currentUser, props?.isThisMyProfile, props?.currentProfile]);

 // Function to handle menu click
  const handleMenuClick = ( event) => {
    // console.log("props handleMenuClick", profileImages);
    // setSelectedImage(profileImages[index]);
    setAnchorEl(event.currentTarget); // Set the menu position
  };
 
  // Handle closing of the menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };  

  const postProfilePicture=async (postAttachments)=>{ 
    let res = [];
    try {

      let isPostWithAttachment = false;
      let relativePath = "/api/post/newwithattachment";
      let filesData = postAttachments || [];
      // console.log("postAttachments",postAttachments)
      let filesDataNew = [];
      let additionalData = [];
      let postData = { 
        // message: postMessage,
        // PostTypeId: postTypeId,
        // PostPrivacyId: postPrivacyId,
      };
      const formData = new FormData();

      // return
      const options = {
        maxSizeMB: 0.2,
        // maxSizeMB: 1,
        maxWidthOrHeight: 2520,
        // maxWidthOrHeight: 1920,
        useWebWorker: true,
        fileType: "image/jpeg",
      };
      
      console.log("filesData",filesData)
      if (filesData) {
        if (filesData.length) {
          relativePath = "/api/profile/addprofilepicture";
          isPostWithAttachment = true;
          for (let index = 0; index < filesData.length; index++) {
            const imageFile = filesData[index];
            // console.log("img imageFile", imageFile);
            // imageFile.name="mesfin.jpg"
            const compressedFile = await imageCompression(imageFile, options);
            // const compressedFile = imageFile;
            filesDataNew.push(compressedFile);
          }

          console.log("compressedFiles",filesDataNew) 
          let filePath = "./public/files/post_files";
          const aa = _.filter(filesDataNew, (file) => {
            formData.append("file", file,file.name);
            formData.append("fileTypeExtension", ".jpg");
            formData.append("folderPath", filePath);
            return file;
          });

          // let postDataRes = Object.entries(postData);
          // postDataRes.forEach((element) => {
          //   formData.append(element[0], element[1]);
          // }); 

          additionalData = {
            headers: {
              "Content-Type": "multipart/form-data",
            }, 
          };
        }
      }
      let getHeaderAndURLRes = await HttpService.getHeaderAndURL();
      let baseUrl = getHeaderAndURLRes.baseUrl;

      // console.log("img chng", formData, additionalData);
      const data = isPostWithAttachment ? formData : postData;
      
      
      // let toastId = toast.loading("posting...",{
      //   position: toast?.POSITION.BOTTOM_LEFT
      // })   
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
    
      // toast?.update(toastId, { render: postFeedRes.success?"posted":"try again", type: postFeedRes.success?"success":"warn", isLoading: false , autoClose: 2000});
      if(postFeedRes?.success){
        queryClient.invalidateQueries(["/api/profile/getbyusername/"+param?.userName+"/profileimages"]);
        updateCurrentUser("myCurrentProfile")
      }
      if (postFeedRes?.record?.length) {
            postFeedRes.record.forEach((imgFile) => {
              // var destination=imgFile.destination.replace('./public',"");
              var destination = imgFile.destination.replace("./public", "/public");
              var filename = "/" + imgFile.filename;
              var pathN = baseUrl + destination + filename;
              var imgpath = pathN;
              res.push(imgpath);
            });
          } 
      // console.log("uploadFiles  ", postFeedRes, res);
      // props?.onClose;
    } catch (err) {
      console.log("uploadFiles err", err);
    }
    return res;
  };

  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };
 
  const handleNewWorkExperianceData = async (field, value) => {
    try {
      let data = newWorkExperianceData;
      if (field && value) {
        data[field] = value;
        setNewWorkExperianceData(data);
        setForceRerender(!forceRerender);
      }
    } catch (error) {}
  };

  const addNewWorkExperiance = async () => {
    // console.log("data", dataD,parentData, dataParentName,fieldType);
    // return
    try {
      let data = newWorkExperianceData;
      let relativePath = "/api/employmentjobseekerworkexperiance/new";
      let refreshVariable = "currentProfile";
      if (data) {
        data.jobSeekerId = currentProfile.id;

        if (data.skillsOrSubjects) {
          let skils = "";
          if (data.skillsOrSubjects.length) {
            data.skillsOrSubjects.forEach((element) => {
              skils = skils + (skils ? "," : "") + element;
            });
          }
          data.skillsOrSubjects = skils;
        }
      }

      if (data.jobSeekerId) {
        if (data) {
          // setLoadingSave(true);
          let changeUserProfileRes = await postDocuments("changeUserInfo", relativePath, data);
          //  toast.update(toastId, { render: changeUserProfileRes.message, type: changeUserProfileRes.success?"success":"warn", isLoading: false , autoClose: 5000});
          // setLoadingSave(false);

          if (changeUserProfileRes.success) {
            setOpenedDialog("");
            updateCurrentUser(refreshVariable);
          }
        }
      }
    } catch (error) {}
  };

  function FriendshipButton() {
    const [friendshipType, setFriendshipType] = React.useState("");
    useEffect(() => {
      if (currentProfile) {
        let fT = ""//CommonServices.checkFriendship(friendshipItemR.id, myFriendships);
       
            if (currentProfile?.iAmFollowing  && currentProfile?.myFollower) {
              fT = "friend";
            } else if (currentProfile?.myFollower) {
              fT = "follower";
            } else if (currentProfile?.iAmFollowing) {
              fT = "following";
            } 
        if (fT) {
          if ( (currentProfile?.id ? true : false)) {
            console.log("fT", fT);
            setFriendshipType(fT);
          }
        }
      }
    }, [currentProfile]);

    let res = <></>;
    try {
      res = (
        <>
          {currentProfile && (
            <>
              {friendshipType == "friend" ? null : friendshipType == "follower" ? (
                <Chip
                  sx={{ px: "15px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    CommonApiServices.follow(currentProfile.id);
                    setFriendshipType("friend");
                  }}
                  // label={"Follow"}
                  label={"Follow Back"}
                />
              ) : friendshipType == "following" ? (
                <Chip
                  sx={{ px: "15px" }}
                  icon={<Logout />}
                  onClick={() => {
                    CommonApiServices.unfollow(currentProfile.id);
                    setFriendshipType("");
                    // console.log("mmmmmmmmmmm", following);
                  }}
                  // label={"Follow"}
                  label={"Unfollow"}
                />
              ) : (
                <Chip
                  sx={{ px: "15px" }}
                  color={"info"}
                  icon={<AddCircle />}
                  onClick={() => {
                    CommonApiServices.follow(currentProfile.id);
                    setFriendshipType("following");
                    // console.log("mmmmmmmmmmm", following);
                  }}
                  // label={"Follow"}
                  label={"Follow"}
                />
              )}
            </>
          )}
        </>
      );
    } catch (error) {
      // console.log("FriendshipListItem errr", error);
    }
    return res;
  }
  
  return (
    <>
      {" "}
      <MKBox mb={2} mt={isMobile ? 2 : 2}> 
        {currentProfile && (
          <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center">
              <Grid p={2}  item xs={12} lg={12}> 
                  <List>
                    {/* <Divider/> */}
                    {currentProfile && (
                      <>
                        <Grid
                          container
                          sx={{ p: "0px" }}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid
                            item
                            xs={12}
                            // md={6} lg={6}
                            px={isMobile ? 0 : 1}
                            py={isMobile ? 0 : 0}
                          >
                            <ListItem  
                            >
                              <ListItemAvatar sx={{ marginRight: "10px" }}>
                                    {/* {currentProfile.isAvailable ? ( */}
                                      <Badge  
                                       badgeContent={((myCurrentProfile?.id!=currentProfile.id))?null:
                                        <IconButton 
                                          onClick={() => {
                                            setOpenedProfilePictureDropZone(true)
                                            // if(!checkAndGoToLoginOrRegisterBox())return
                                            // CommonApiServices.follow(post?.Profile?.id);
                                            // setIAmFollowing(!iAmFollowing);
                                          }
                                        } 
                                          sx={{mt:"-9px"}} color="error" aria-label="delete" size="large">
                                         <Avatar  sx={{mt:"-9px", width:30, height:30, color:"rgb(0 2 5)"}} > <CameraEnhance fontSize="inherit" /></Avatar> 
                                        </IconButton>} 
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                        // sx={{  marginLeft: "-19px" }}
                                        // color="secondary"
                                        // overlap="circular"
                                        // variant="dot"
                                      >
                                         {/* <PhotoSlider
                                          images={
                                            profileImages?.length?(
                                            CommonServices?.getPostAttachments({attachments:profileImages })?.map((item) => ({
                                            src: item,
                                            key: item,
                                          }))):[{src: currentProfile?.profileImageUrl,
                                                key: currentProfile?.profileImageUrl,}]}
                                          visible={ profileImagesSliderVisible}
                                          // visible={photoSliderVisible}
                                          onClose={() => {
                                            setProfileImagesSliderVisible(false);
                                            setProfileImagesSliderIndex(0)
                                            // navigateComp(-1);
                                          }}
                                          index={profileImagesSliderIndex}
                                          onIndexChange={setProfileImagesSliderIndex}
                                        />  */}



      {/* Invisible div to serve as the dynamic gallery for PhotoSwipe */}
      <div id="dynamic-gallery" style={{ display: 'none' }}>
        {profileImages?.length?(CommonServices?.getAttachmentsWithHighAndLowQualityImages({attachments:profileImages})?.map((attachment, index) => (
          <a
            key={index}
            href={attachment?.image?.highQualityImage?.fullPath}
            data-pswp-width={attachment?.image?.highQualityImage?.width}
            data-pswp-height={attachment?.image?.highQualityImage?.height}
          >
            <img src={attachment?.image?.lowQualityImage?.fullPath} alt={`Thumbnail ${index + 1}`} />
          </a>
        ))):(currentProfile?.profileImageUrl?(
           <a
            key={0}
            href={currentProfile?.profileImageUrl}
            // data-pswp-width={attachment?.image?.highQualityImage?.width}
            // data-pswp-height={attachment?.image?.highQualityImage?.height}
          >
            <img src={currentProfile?.profileImageUrl} alt={`Thumbnail ${1}`}  style={{
          width: '100%',
          height: 'auto',
          maxWidth: '100%',
          objectFit: 'contain',
          display: 'block',
        }} />
          </a> 
        ):null)}
      </div>
 {profileImages?.length?<Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        
          style= {{
            zIndex: 124000, // Ensure this is above the lightbox
          }}
        PaperProps={{
          style: {
            zIndex: 12400, // Ensure this is above the lightbox
          },
        }}
      >
     {(myCurrentProfile?.id==currentProfile.id)?
        <MenuItem onClick={deleteSelectedProfileAttachment}>
          <Delete style={{ marginRight: 8 }} /> Delete
        </MenuItem>:null}
        <MenuItem disabled onClick={() => { /* handle share logic */ }}>
          <Share style={{ marginRight: 8 }} /> Share
        </MenuItem>
        <MenuItem disabled onClick={() => { /* handle save logic */ }}>
          <SaveAlt style={{ marginRight: 8 }} /> Save Image
        </MenuItem>
      </Menu>:null}

        


                                      {/* <PhotoProvider>
                                        {" "}
                                        <PhotoView key={1} src={currentProfile.profileImageUrl}> */}
                                        <Avatar
                                          onClick={ 
                                            handleOpenGallery }
                                          sx={{
                                            width: isMobile ? 90 : 100,
                                            height: isMobile ? 90 : 100,
                                            marginBottom: "-10px",
                                            marginRight: "-10px",
                                          }}
                                          variant="circular"
                                          src={
                                            currentProfile
                                              ?(
                                            currentProfile?.ProfileImages?.length?
                                            ((CommonServices?.getPostAttachments({attachments:currentProfile?.ProfileImages,thumbnail:true,firstUrl:true})))
                                               :currentProfile?.profileImageUrl)
                                              : profilePicture
                                          }
                                          // size="xl"
                                          // shadow="xl"
                                        />
                                        {/* </PhotoView>
                                      </PhotoProvider> */}
                                      </Badge>
                                    {/* ) : (
                                      <Avatar
                                        sx={{
                                          width: isMobile ? 90 : 100,
                                          height: isMobile ? 90 : 100,
                                        }}
                                        variant="circular"
                                        src={
                                          currentProfile
                                            ? currentProfile.profileImageUrl
                                            : profilePicture
                                        } 
                                      />
                                    )} */}
                              </ListItemAvatar>
                              <ListItemText sx={{ml:2}}
                              
                                secondary={
                                <TruncateMarkup
                                    lines={5} 
                                  >
                                  <MKTypography variant={ "body2" }>
                                    {"@"+currentProfile?.userName}
                                  </MKTypography>
                                </TruncateMarkup>
                                }
                                primary={
                                    <TruncateMarkup
                                        lines={5} 
                                      >
                                      <MKTypography variant={ "h5" }>
                                        {CommonServices.getProfileName(currentProfile)}
                                      </MKTypography>
                                      </TruncateMarkup>
                                }
                              />
                            </ListItem>
                          </Grid>
                          {/* <Divider orientation="vertical" flexItem />  */}
                          <Grid
                            item
                            xs={12}
                            // md={6}
                            // lg={6}
                            px={isMobile ? 0 : 3}
                            py={isMobile ? 0 : 0}
                            alignItems="flex-end"
                          >
                            <ListItem>
                              <ListItemText
                                primary={
                                  <Grid
                                    sx={{ my: 2 }}
                                    container
                                    direction="row"
                                    justifyContent={isMobile ? "space-around" : "flex-start"}
                                    alignItems="flex-start"
                                  >
                                    <Box
                                      sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}
                                      onClick={() => {
                                        let data = {
                                          dialogId: "FriendshipDialogBox",
                                          data: {
                                            profile: currentProfile,
                                            friendshipTab: "followers",
                                          },
                                        };
                                        updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }}
                                    >
                                      <Typography color="dark" variant="h6" size="small">
                                        {currentProfile.followersCount || 0}
                                      </Typography>
                                      <Typography variant="caption">Followers</Typography>
                                    </Box>
                                    <Box
                                      sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}
                                      onClick={() => {
                                        let data = {
                                          dialogId: "FriendshipDialogBox",
                                          data: {
                                            profile: currentProfile,
                                            friendshipTab: "followings",
                                          },
                                        };
                                        updateCurrentUser("currentGlobalDialogBoxData", data);
                                      }}
                                    >
                                      <Typography color="dark" variant="h6" size="small">
                                        {currentProfile.followingCount || 0}
                                      </Typography>
                                      <Typography gutterBottom variant="caption">
                                        Following
                                      </Typography>
                                    </Box>
                                    <Box sx={{ mx: 1, alignItems: "center", cursor: "pointer" }}>
                                      <Typography variant="h6" size="small">
                                        {currentProfile.postsCount || 0}
                                      </Typography>
                                      <Typography onClick={()=>{sendemailforeveryprofile()}} gutterBottom variant="caption">
                                        Posts
                                      </Typography>
                                    </Box> 
                                  </Grid>
                                }
                                secondary={
                                  currentProfile &&
                                  myCurrentProfile && (
                                    <>
                                      {isThisMyProfile ? (
                                        <>
                                          <Chip
                                            sx={{ mr: "13px", px: "15px" }}
                                            icon={<Edit />}
                                            component={Link}
                                            to={"/Settings/Account"}
                                            // label={"Follow"}
                                            label={"Edit Profile"}
                                          />
                                          {/* <Chip
                                          sx={{ mr: "13px", px: "15px" }}
                                          icon={<Edit />}
                                          onClick={() => {
                                            // EditProfileDialogBox
                                            let data = {
                                              dialogId: "EditProfileDialogBox",
                                              data: {
                                                // profile: currentProfile,
                                                // friendshipTab: "followers",
                                              },
                                            };
                                            updateCurrentUser("currentGlobalDialogBoxData", data);
                                          }}
                                          // label={"Follow"}
                                          label={"Creator Tools"}
                                        /> */}
                                        </>
                                      ) : (
                                        <>
                                          <Chip
                                            sx={{ mx: "13px", px: "15px" }}
                                            icon={<Send />} 
                                              onClick={() => {  
                                                let chatId=currentProfile?.participants[0]?.id||"new"
                                                navigate("/chats/1/"+chatId+"/"+currentProfile?.id);
                  
                                            }
                                           }
                                            // label={"Follow"}
                                            label={"Message"}
                                          />
                                          <FriendshipButton />
                                        </>
                                      )}{" "}
                                    </>
                                  )
                                }
                              />
                            </ListItem>
                          </Grid>
                        </Grid>
                        {(currentProfile.title || isThisMyProfile) && (
                          <>
                            <Divider sx={{ m:1, pb: 0 }} />
                            <ListItem>
                              <ListItemText
                                secondary={
                                  <MKTypography variant="body2">
                                    {currentProfile.title}
                                  </MKTypography>
                                }
                                // primary={
                                //   <MKTypography sx={{ mt: 0 }} variant="caption">
                                //     {"Title"}
                                //   </MKTypography>
                                // }
                              />
                            </ListItem>
                          </>
                        )}

                   
                      </>
                    )}
                  </List> 
              </Grid>
            </Grid>
          </Card>
        )} 
      </MKBox>

      <DropzoneDialog
    acceptedFiles={['image/*']}
    filesLimit={1}
    cancelButtonText={"cancel"}
    submitButtonText={"submit"}
    maxFileSize={5000000}
    open={openedProfilePictureDropZone}
    onClose={() => setOpenedProfilePictureDropZone(false)}
    onSave={(files) => {
      // console.log('Files:', files);
      setOpenedProfilePictureDropZone(false);
      postProfilePicture(files)
    }}
    showPreviews={true}
    showFileNamesInPreview={true}
  />
      {forceRerender}
      <Dialog
        open={openedDialog == "addWorkExperiance"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        <DialogTitle>Add Profession and Work Experiance</DialogTitle>
        <DialogContent style={{ paddingTop: "15px" }}> 

          {jobs && (
            <Autocomplete
              fullWidth
              // sx={{ width: 400 }}
              onChange={(e, val) => {
                if (val) {
                  // console.log("val",val);
                  //  value=val;
                  handleNewWorkExperianceData("jobId", val.id);
                  // console.log("value",value);
                }
              }}
              options={jobs}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <MKInput {...params} label="Profession or Job" variant="outlined" />
              )}
            />
          )}

          <TextField
            autoFocus
            margin="dense"
            id="expectingSalaryPerMonth"
            defaultValue={newWorkExperianceData.expectingSalaryPerMonth}
            label="Expecting Salary (Per Month)"
            type="number"
            onChange={(e) => {
              handleNewWorkExperianceData("expectingSalaryPerMonth", e.target.value);
            }}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="workExperianceInMonth"
            defaultValue={newWorkExperianceData.workExperianceInMonth}
            onChange={(e) => {
              handleNewWorkExperianceData("workExperianceInMonth", e.target.value);
            }}
            label="Work Experiance (In Month)"
            type="number"
            fullWidth
            variant="outlined"
          />

          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            // defaultValue={[top100Films[13].title]}
            // defaultValue={newWorkExperianceData.skillsOrSubjects}
            onChange={(e, data) => {
              handleNewWorkExperianceData("skillsOrSubjects", data);
            }}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Skills Or Subjects"
                placeholder="Add Skill"
              />
            )}
          />

          <TextField
            autoFocus
            margin="dense"
            multiline
            rows={4}
            id="Describe"
            defaultValue={newWorkExperianceData.description}
            onChange={(e) => {
              handleNewWorkExperianceData("description", e.target.value);
            }}
            label="More Describtion (about your skills) "
            // type="number"
            fullWidth
            variant="outlined"
          />
          {/* newWorkExperianceData */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={()=>{setOpenedDialog("")}}>Cancel</Button> */}
          <MKButton
            color="success"
            // variant="text"
            // size="small"
            disabled={!newWorkExperianceData.jobId}
            onClick={() => {
              addNewWorkExperiance();
            }}
          >
            <strong> Save </strong>
          </MKButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{moreText.title}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{moreText.content}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      {isMobile ? (
        <SwipeableDrawer
          // sx={{ zIndex: 1111111 }}
          // container={container}
          anchor="bottom"
          open={openedDialog == "switchAgency"}
          onClose={() => setOpenedDialog("")}
          // onOpen={()=>setDrawer2Opened("switchAgency")}
          swipeAreaWidth={150}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SwitchMyProfilesBox
            onClose={() => {
              setOpenedDialog("");
            }}
          />
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={openedDialog == "switchAgency"}
          // TransitionComponent={Transition}
          scroll={"paper"}
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
          maxWidth="xs"
          onClose={() => setOpenedDialog("")}
          fullScreen={isMobile ? true : false}
        >
          <DialogTitle>Profiles </DialogTitle>
          <DialogContent
            dividers={true}
            sx={{ padding: "0rem", pb: "35px", minHeight: isMobile ? "300px" : "460px" }}
          >
            <SwitchMyProfilesBox
              onClose={() => {
                setOpenedDialog("");
              }}
            />
          </DialogContent>
          {/* </>
          )} */}
        </Dialog>
      )}
    </>
  );
}

export default ProfileHeader;
