// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images 
import {
  AppBar, 
  Box, 
  DialogContent,
  Grid,
  IconButton, 
  Toolbar,
  Typography,
} from "@mui/material";
import {  ArrowBack  } from "@mui/icons-material"; 
import { isMobile } from "react-device-detect"; 
import UserProfile from "pages/UserProfile";

function ProfileDialog(props) { 
  const [forceRerender, setForceRerender] = React.useState(true); 
  
  return (
    <>

      <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => {
                      props?.onClose();
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                      Profile
                  </Typography>
                  {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
              </AppBar>
      </Box>

    <DialogContent sx={{ minHeight:isMobile?"900px": "760px" , p: "0px",m:"0px"}}>

   { <UserProfile
     tab={props?.currentGlobalDialogBoxData?.data?.tab}
     profile={props?.currentGlobalDialogBoxData?.data?.profile} />}
     
    </DialogContent>
      {forceRerender}
  </> );
}

export default ProfileDialog;
