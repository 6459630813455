/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// Material Kit 2 PRO React components
import queryString from "query-string";

import {  useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
 
import { Avatar, BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import { Apps, Home, ShoppingCart, Work } from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

function BottomNavBar() {
  const [currentData] = useCurrentUser();
  const { myCurrentProfile, isUserLoggedIn } = currentData;

  const [selectedNav, setSelectedNav] = React.useState("/home");
  const [profileSectionIndex, setProfileSectionIndex] = React.useState(0);
  const [forceRerender, setForceRerender] = React.useState(false);

  useEffect(() => {
    setForceRerender(!forceRerender);
  }, [isUserLoggedIn, myCurrentProfile]);

  useEffect(() => {
    // tab=2
    if (location.search) {
      let locationSearch = queryString.parse(location.search);
      if (locationSearch.tab) {
        setProfileSectionIndex(locationSearch.tab);
      }
    }
  }, [location.search]);

  
  useEffect(() => {
    // tab=2
    if (location.pathname) { 
      let pt=location.pathname?.toLowerCase()
      if (pt?.startsWith("/home")){
        setSelectedNav("/home")
      } else if (pt?.startsWith("/jobs")){
        setSelectedNav("/jobs")
      } else if (pt?.startsWith("/market-place")){
        setSelectedNav("/Market-Place")
      }  else if (pt?.startsWith("/profile")){
        setSelectedNav("/Profile")
      }  
      console.log("location.pathname",location.pathname)
    }
  }, [location.pathname]);


  const navigate = useNavigate();


  return (
    <>
      {forceRerender}
      <Box pt={6} px={1} mt={6}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 111,
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={selectedNav}
            onChange={(event, newValue) => {
              console.log("value", newValue);
              navigate(newValue);
              setSelectedNav(newValue);
            }}
          >
            <BottomNavigationAction
              sx={{ minWidth: "73px" }}
              value={"/home"}
              selected={location?.pathname?.toLowerCase()?.startsWith("/home")}
              label="Home"
              icon={<Home />}
            />
            <BottomNavigationAction
              sx={{ minWidth: "73px" }}
              value={"/jobs"}
              label="Jobs" 
              selected={location?.pathname?.toLowerCase()?.startsWith("/jobs")}
              icon={<Work />}
            />
            <BottomNavigationAction
              sx={{ minWidth: "73px" }}
              value={"/Market-Place"}
              selected={location?.pathname?.toLowerCase()?.startsWith("/market-place")}
              label="Market"
              icon={<ShoppingCart sx={{ width: 25, height: 25 }} />}
            />
            <BottomNavigationAction
              sx={{ minWidth: "73px" }}
              value={"/Find"}
              label="Find"
              selected={location?.pathname?.toLowerCase()?.startsWith("/find")}
              icon={<Apps />}
            />
            <BottomNavigationAction
              sx={{ minWidth: "73px" }}
              selected={location?.pathname?.toLowerCase()?.startsWith("/profile")}
              value={
                myCurrentProfile
                  ? myCurrentProfile.userName
                    ? "/" + myCurrentProfile.userName
                    : "/Profile/"
                  : "/Profile/"
              }
              label="Profile"
              icon={
                <Avatar sx={{ width: 25, height: 25 }} src={
                  myCurrentProfile
                    ?(
                      myCurrentProfile?.ProfileImages?.length?
                  ((CommonServices?.getPostAttachments({attachments:myCurrentProfile?.ProfileImages,thumbnail:true,firstUrl:true})))
                     :myCurrentProfile?.profileImageUrl)
                    : ""
                } />
                // <Person />
              }
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
}

export default BottomNavBar;
