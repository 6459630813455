// @mui material components
import React, { useEffect } from "react";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Dialog, DialogContent, Drawer, Slide } from "@mui/material";
import { isMobile } from "react-device-detect";  
import ProfileDialog from "./Sections/ProfileDialog";
import { useNavigate, useParams } from "react-router-dom";
 
function ProfileDialogBox(props) { 
  const navigate = useNavigate();
  const params = useParams();
 const [forceRerender, setForceRerender] = React.useState(true); 
  const [currentData] = useCurrentUser();
  const { currentGlobalDialogBoxData} =  currentData;
   const [dialogOpened, setDialogOpened] = React.useState(false); 
 

  window.addEventListener("popstate", (event) => {setDialogOpened(false)})

  useEffect(async () => { 
    if ( currentGlobalDialogBoxData?.dialogId == "ProfileDialogBox") {
      let path
      path="/"+currentGlobalDialogBoxData?.data?.profile?.userName; 
      if(currentGlobalDialogBoxData?.data?.tab){
        path=path+"/"+currentGlobalDialogBoxData?.data?.tab
      } 
      window.history.pushState(null, "Profile Details", path); 
        setDialogOpened(true)
      } 
  }, [ currentGlobalDialogBoxData]);

  React.useEffect(() => {  
    if ( !(params?.userName)) { 
      // setDialogOpened(false) 
    }  
}, [location.pathname]);

  return (
    <>
      {((currentGlobalDialogBoxData ? (currentGlobalDialogBoxData.dialogId ? true : false) : false)
        ? true
        : false) && (
        <>
          {/* {isMobile ? ( */} 
            {/* {isMobile ?  */}
            <Dialog
              open={dialogOpened}
              // TransitionComponent={Transition}
              // TransitionComponent={Transition}
              scroll={"paper"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }}
              maxWidth="md"
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen//</>={isMobile ? true : false}
            >
              {/* {currentGlobalDialogBoxData.data && (
            <> */}
              <ProfileDialog
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
                currentGlobalDialogBoxData={currentGlobalDialogBoxData} 
              />
              {/* </>
          )} */}
            </Dialog>
          {/* : (
            <Drawer
            open={dialogOpened}
              // TransitionComponent={Transition}
              scroll={"paper"}
              anchor={"right"}
              sx={{ "& .MuiDialog-paper": { width: "100%" } }}
              // maxWidth="xs"
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
              fullScreen//={isMobile ? true : false}
            > 
              <ProfileDialog
              onClose={() => {
                setDialogOpened(false) 
                navigate(-1)
              }}
                currentGlobalDialogBoxData={currentGlobalDialogBoxData} 
              />
            </Drawer>
          )}   */}
        </>
      )}
      {forceRerender}
    </>
  );
}

export default ProfileDialogBox;
