/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Fragment, useState, useEffect } from "react";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbarDropdown from "examples/Navbars/DefaultNavbar/DefaultNavbarDropdown";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Kit 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
// import logo from "assets/logos/logoBF.png";
import logo from "assets/logos/logoBF1.png";
// import logo from "assets/logos/logo4.png";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  Avatar, 
  AvatarGroup, 
  Badge, 
  Drawer,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Tooltip, 
} from "@mui/material";
import {
  Add, 
  ArrowDownward, 
  ArrowDropDown, 
  ArrowDropDownCircle, 
  ArrowDropDownCircleOutlined, 
  ArrowDropDownCircleTwoTone, 
  Group, 
  History, 
  Logout,
  Mail,
  MenuRounded, 
  Notifications,
  Search, 
  Settings
} from "@mui/icons-material";
import { UseAuth } from "Api-Services/useAuth"; 
import { CommonServices } from "Api-Services/commonServices";
import SideBarMenuContent from "./SideBarMenuContent";
import SearchBoxContent from "./SearchBoxContent";
 
function DefaultNavbar({ brand, routes, transparent, light, action, sticky, relative, center }) {
  const [dropdown, setDropdown] = useState("");
  const [dropdownEl, setDropdownEl] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [nestedDropdownEl, setNestedDropdownEl] = useState("");
  const [nestedDropdownName, setNestedDropdownName] = useState(""); 
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);

  const [currentData, updateCurrentUser] = useCurrentUser();
  const {
    myCurrentProfileLoading,
    myCurrentUserLoading,
    isUserLoggedIn,
    myCurrentProfile,
    agencyData,
    isAgentWebsite,
    notificationsCount,
    myProfiles,
    chatsCount,
  } = currentData;
  const openMobileNavbar = () => {
    setMobileNavbar(!mobileNavbar);
    setMobileView(!mobileNavbar);
  };

  React.useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myCurrentProfile, isUserLoggedIn]);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(true);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // const open = Boolean(anchorEl);
  const handleClick = (event, field) => {
    setOpen(field);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen("");
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const goToPage = (page) => {
    setAnchorEl(null);
    navigate(page);
  };

  const searchByKeyword = async () => {
    try {
      let pathSearch;
      if (searchKeyword) {
        // jobSearchData = jobSearchFieldsandValue;
        pathSearch = { keyword: searchKeyword };
        pathSearch = CommonServices.encryptLocalData(pathSearch);
        let path = "/pages/jobs?search=" + pathSearch; //+"&employmentAgencyId="+jobSeeker.employmentAgencyId
        navigate(path);
      }
    } catch (error) {}
  };

 

  const logout = () => {
    setAnchorEl(null);
  };

  const renderNavbarItems = routes.map(
    ({ isAllowedForAgent, name, icon, href, route, collapse }) => {
      let routeD = (
        <DefaultNavbarDropdown
          key={name}
          name={name}
          icon={icon}
          href={href}
          route={route}
          collapse={Boolean(collapse)}
          onMouseEnter={({ currentTarget }) => {
            if (collapse) {
              setDropdown(currentTarget);
              setDropdownEl(currentTarget);
              setDropdownName(name);
            }
          }}
          onMouseLeave={() => collapse && setDropdown(null)}
          light={light}
        />
      );

      if (agencyData && isAgentWebsite) {
        if (isAllowedForAgent) {
          return routeD;
        }
      } else {
        return routeD;
      }
    }
  );
 
  
 
  return (
    <>
      {/* <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}> */}
      <MKBox
        py={0}
        px={{ xs: 0, sm: 0, lg: 0 }}
        // px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        // my={relative ? 0 : 2}
        // mx={relative ? 0 : 3}
        // mx={relative ? 0 : 3}
        // width={relative ? "100%" : "calc(100% - 48px)"}
        // width={relative ? "100%" : "calc(100% - 48px)"}
        width={"100%"}
        borderRadius="xl"
        // shadow={transparent ? "none" : "md"}
        shadow={"xs"}
        // color={light ? "white" : "dark"}
        color={"white"}
        // position={relative ? "relative" : "absolute"}
        left={0}
        top={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          // backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backgroundColor: rgba(white.main, 1.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
          position: "fixed",
        })}
      >
        <Grid
          m={0}
          container
          justifyContent="space-between"
          alignItems="flex-start"
          display="flex"
          // justifyContent="space-between" alignItems="center"
        >
          {/* lg={3.2} */}
          <Grid
            item
            xs={4}
            sm={6}
            md={3}
            lg={3.2}
             xl={3.5}
            // component={Link}
            // to="/"
            // lineHeight={1}
            // // py={transparent ? 1.5 : 0.75}
            // pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
          >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {/* <img
                  style={{ height: "55px", marginRight: "8px" }}
                
                /> */}
              <Avatar
                color="primary"
                sx={{ width: 50, mx: 2, height: 50 }}
                component={Link}
                to={"/"}
                src={logo}
              />

              <Paper
                // component="form"

                onClick={(e) => {
                  setDrawerOpened("search");
                }}
                square={false}
                elevation={0}
                shadow={"none"}
                sx={{
                  mx: 2,
                  mt: 0.5,
                  backgroundColor: "#f0f2f5",
                  // mr: "6px",
                  display: "flex",
                  // alignItems: "center",
                  // width: "158px",
                  width: "min-content",
                }}
              >
                <IconButton
                  // disabled={!searchKeyword}
                  type="button"
                  // sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <Search
                    onClick={() => {
                      searchByKeyword();
                    }}
                  />
                </IconButton>
                <InputBase
                  sx={{
                    display: { xs: "none", sm: "none", md: "none", lg: "flex", width: "114px" },
                  }}
                  // sx={{ ml: 1, flex: 1 }}
                  placeholder="Search "
                  onClick={(e) => {
                    setDrawerOpened("search");
                  }}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    setDrawerOpened("search");
                  }}
                  type="text"
                  // inputProps={{
                  //   "aria-label": "search google maps",
                  //   onKeyDown:(event)=>{
                  //     if (event.keyCode) {
                  //       if (event.keyCode === 13) {searchByKeyword()}
                  //       }
                  //    }
                  // }}
                />
              </Paper>

              {/* <MKTypography variant="h3" fontWeight="bold" color={light ? "white" : "dark"}>
                  Baally
                </MKTypography> */}
            </Grid>
          </Grid>
          <Grid
            item
            md={5}
            lg={5.8}
            xl={5}
            display={{ xs: "none", sm: "none", md: "flex", lg: "flex" }}
            // color="white"
            // // color="inherit"
            // display={{ xs: "none", lg: "flex" }}
            // ml="auto"
            // mr={center ? "auto" : 0}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {renderNavbarItems}
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            lg={3}
            // sx={{ display: "flex" }}
            alignItems="center"
          >
            {" "}
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              // justifyContent="space-between" alignItems="center"
            >
              {myCurrentProfileLoading && myCurrentUserLoading ? (
                <></>
              ) : isUserLoggedIn ? (
                <>
                  {myCurrentProfile ? (
                    <>
                      <Tooltip title="Notifications" sx={{ display: { md: "flex", lg: "none" } }}>
                        <IconButton
                          onClick={() => {
                            let data = {
                              dialogId: "SideBarMenuDialogBox",
                              data: {},
                            };
                            updateCurrentUser("currentGlobalDialogBoxData", data);
                          }}
                          size="small"
                          // sx={{ ml: 2 }}
                          // aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          // aria-expanded={open ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 33, height: 33 }}>
                            <MenuRounded color="dark" />
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Create post">
                        <IconButton  
                          onClick={() => {
                           let data = {
                             dialogId: "CreatePostDialogBox",
                             data: {},
                           }; //createPostDialogContent
                           updateCurrentUser("currentGlobalDialogBoxData", data);
                           //   setFriendshipTab("suggestions")
                           //  setDrawerOpened("freindship")
                         }}
                          size="small"
                          // sx={{ ml: 2 }}
                          // aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          // aria-expanded={open ? "true" : undefined}
                        >
                          <Avatar sx={{ width: 35, height: 35 }}>
                            <Add color="dark" />
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Notifications">
                        <IconButton
                         onClick={() => {
                            updateCurrentUser("notificationsCount",0) 
                            let data = {
                              dialogId: "NotificationsDialogBox",
                              data: {},
                            }; 
                            updateCurrentUser("currentGlobalDialogBoxData", data);
                          //   setFriendshipTab("suggestions")
                          //  setDrawerOpened("freindship")
                        }} 
                          size="small"
                          // sx={{ ml: 2 }}
                          // aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          // aria-expanded={open ? "true" : undefined}
                        >
                        <Badge badgeContent={notificationsCount} color="error">
                          <Avatar sx={{ width: 35, height: 35 }}>
                            <Notifications color="dark" />
                          </Avatar>
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Messages">
                        <IconButton
                         
                         onClick={() => {
                            updateCurrentUser("chatsCount",0) 
                            let data = {
                              dialogId: "MessageDialogBox",
                              data: {},
                            }; 
                            updateCurrentUser("currentGlobalDialogBoxData", data);
                          //   setFriendshipTab("suggestions")
                          //  setDrawerOpened("freindship")
                        }}
                          size="small"
                          // sx={{ ml: 4 }}
                          aria-haspopup="true"
                        >
                            <Badge badgeContent={chatsCount} color="error">
                          <Avatar sx={{ width: 35, height: 35 }}>
                              <Mail color="dark" /> 
                          </Avatar>
                            </Badge>
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Friendship"> 
                        <IconButton
                          size="small"
                          // sx={{ ml: 4 }}
                          aria-haspopup="true"
                          onClick={() => {
                            let data = {
                              dialogId: "FriendshipDialogBox",
                              data: {
                                profile: myCurrentProfile,
                                friendshipTab: "followers",
                              },
                            };
                            updateCurrentUser("currentGlobalDialogBoxData", data);
                          }} 
                        >
                        <Avatar sx={{ width: 35, height: 35 }}>
                            <Group color="dark"/>
                          </Avatar>
                        </IconButton> 
                      </Tooltip>
                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={(e) => handleClick(e, "profile")}
                          size="small"
                          // sx={{ ml: 4 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        >
                          {/* <div style={{ width: "60px" }}> */}
                          <Badge 
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                           badgeContent={<ArrowDropDownCircleOutlined color="dark" sx={{ width: 16, height: 16 }}/>}  >
                          <Avatar sx={{ width: 40, height: 40 }} 
                              src={ 
                               myCurrentProfile?.ProfileImages?.length?
                                ((CommonServices?.getPostAttachments({attachments:myCurrentProfile?.ProfileImages,thumbnail:true,firstUrl:true})))
                                   :myCurrentProfile?.profileImageUrl 
                            }></Avatar>
                          {/* </div> */}
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open == "profile"}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: "auto",
                            // overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      > 
                      <SideBarMenuContent logout={logout}/>
                      </Menu>
                    </>
                  ) : (
                    <MKButton
                      // component={Link}
                      sx={{ mt: 1 }}
                      onClick={() => {
                        let data = {
                          dialogId: "SwitchProfileDialogBox",
                          data: {},
                        };
                        updateCurrentUser("currentGlobalDialogBoxData", data);
                        //   setFriendshipTab("suggestions")
                        //  setDrawerOpened("freindship")
                      }}
                      variant={"outlined"}
                      // variant={
                      //   action.color === "white" || action.color === "default"
                      //     ? "contained"
                      //     : "gradient"
                      // }
                      color={"info"}
                      size="small"
                    >
                      {"Enter to profile"}
                    </MKButton>
                  )}
                </>
              ) : (
                <MKButton
                  sx={{ mt: 1 }}
                  // component={Link}
                  // to={action.route}

                  onClick={() => {
                    UseAuth.goToAuthLoginPage();
                  }}
                  // onClick={()=>{goToPage(action.route)}}
                  variant={
                    action.color === "white" || action.color === "default"
                      ? "contained"
                      : "gradient"
                  }
                  color={"info"}
                  // size="small"
                >
                  {action.label}
                </MKButton>
              )}
            </Grid>
          </Grid>
        </Grid>

        <MKBox
          bgColor={transparent ? "white" : "transparent"}
          shadow={transparent ? "lg" : "none"}
          borderRadius="xl"
          px={transparent ? 2 : 0}
        >
          {mobileView && (
            <DefaultNavbarMobile
              routes={routes}
              open={mobileNavbar}
              closeMobleMenu={() => {
                openMobileNavbar();
              }}
            />
          )}
        </MKBox>
      </MKBox>
      {/* {dropdownMenu}
      {nestedDropdownMenu} */}
      {/* </Container> */}

      <Drawer
        anchor={"left"}
        open={drawerOpened == "search"}
        onClose={() => {
          setDrawerOpened(false);
        }}
      >
        <SearchBoxContent
          searchKeyword={searchKeyword}
          onClose={() => {
            setDrawerOpened(false);
          }}
        />
      </Drawer>
       
      {forceRerender}
    </>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  brand: "Extreme Totors",
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
