/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

import queryString from "query-string";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
// import Posts from "pages/Blogs/Author/sections/Posts";
// import Contact from "pages/Blogs/Author/sections/Contact";
// import Footer from "pages/Blogs/Author/sections/Footer";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect, useState } from "react";
// import Profile from "./sections/Profile";
import { AppBar, Button, Container, Divider, Grid, IconButton, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { ArrowBack, FeedRounded, Person } from "@mui/icons-material";
// import JobVacanciesPreview from "pages/Search/SearchJobs/components/JobVacanciesPreview";
import { DataManagementService } from "Api-Services/dataManagementService";
import AboutUser from "./Sections/AboutUser";
import UserPosts from "./Sections/UserPosts";
import ProfileHeader from "./Sections/ProfileHeader";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileApiServices } from "Api-Services/profileApiServices";
import { useQuery } from "@tanstack/react-query";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import TruncateMarkup from "react-truncate-markup";

function UserProfile(props) {
  const paraaa = useParams();
  const navigate = useNavigate();
  const [currentData] = useCurrentUser();
  const { isUserLoggedIn, myCurrentProfile } = currentData;
  const [profileSectionIndex, setProfileSectionIndex] = useState(paraaa?.tab||props?.tab||"posts");
  const [forceRerender, setForceRerender] = React.useState(false);
  const userName=paraaa?.userName||props?.profile?.userName


  //////
  useEffect(() => {
    setForceRerender(!forceRerender);
  }, [myCurrentProfile, isUserLoggedIn]);

  let {data:profile} = useQuery({
    queryKey: ["/api/profile/getbyusername/"+userName],
    queryFn: async () => { 
      return await HttpService.getApiData("/api/profile/getbyusername/"+userName);
    },
    initialData:props?.profile
  }); 
 
  const onTabChange=(tabD)=>{
    setProfileSectionIndex(tabD) 
    if(userName){
    let path= "/"+userName+"/"+tabD ;  
    window.history.pushState(null, "  aboutpage", path); 
  }
}

  return (
    <> 

<Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid 
            item
            sm={12}
            xs={12}
            md={4.5}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}
            >
          </Grid>
        <Grid item 
        // xs={12} lg={(isMobile||((props?.post)))?12:8} 
        xs={12} sm={12} md={7} lg={5} xl={5} 
        // sx={{mt:isMobile?0:2}} 
        > 
        
         {/* {!props?.post && ( */}
          {/* <AppBar position="static">
            <Toolbar style={{minHeight:"0px", py:"0px"}}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => {
                  navigate(-1); 
                }}
              >
                <ArrowBack />
              </IconButton>
              <TruncateMarkup
                  lines={2} 
                >
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
               {CommonServices?.getProfileName(profile)}
              </Typography>
              </TruncateMarkup> 
            </Toolbar>
          </AppBar> */}
          <MKBox my={1}>
            
            <Container sx={{ px: "3px",  mx: "0px" }}>
              <ProfileHeader isThisMyProfile={myCurrentProfile?.userName == userName} currentProfile={profile} />
              <Tabs
                value={profileSectionIndex}
                onChange={(e, val) => {
                  onTabChange(val);
                }}
                aria-label="icon position tabs example"
              >
                <Tab icon={<FeedRounded />} value="posts" iconPosition="start" label="Posts" />
                <Tab icon={<Person />} value="about"  iconPosition="start" label="About" />
              </Tabs>
            </Container>
            <Divider sx={{my:1}} />
            {/* </Grid>
          </Grid>  */}
            {profileSectionIndex =="posts"  && (
              <UserPosts isThisMyProfile={myCurrentProfile?.userName == userName} currentProfile={profile} />
            )}
            {profileSectionIndex =="about"  && (
              <AboutUser isThisMyProfile={myCurrentProfile?.userName == userName} currentProfile={profile} />
            )}
            {/* {((profileSectionIndex == 2 )&&isThisMyProfile)&& <ProfileInformation isThisMyProfile={isThisMyProfile}   currentProfile={currentProfile}/>} */}

            {/* </Container> */}
          </MKBox> 
       </Grid>
        <Grid
            item
            sm={12}
            xs={12}
            md={4.5}
            lg={3.5}
            xl={2.3}
            display={{ xs: "12", sm: "12", md: "flex" }}>
          
        </Grid>
      </Grid>



      {forceRerender}
    </>
  );
}

export default UserProfile;
