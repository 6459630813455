// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext"; 
import { CommonApiServices } from "Api-Services/commonApiServices";
import { Link, useNavigate, useParams } from "react-router-dom"; 
import { Avatar, Conversation, ConversationList, Search, Sidebar } from "@chatscope/chat-ui-kit-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AppBar, Box, Button, Divider, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, Toolbar, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { isMobile } from "react-device-detect";
import ReactTimeago from "react-timeago";
import dayjs from "dayjs";

import parse from "html-react-parser";
import { CommonServices } from "Api-Services/commonServices";
var relativeTime = require('dayjs/plugin/relativeTime')
function ChatsList(props) {
  // profile={myCurrentProfile} friendshipTab={friendshipTab}
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient()
  const [currentData] = useCurrentUser();
  const { myFriendships,chats, myCurrentProfile } = currentData;
  const [forceRerender, setForceRerender] = React.useState(true);  
  // const [profileSearchResult, setProfileSearchResult] = React.useState([]);  
  const [isSearchFocussed, setIsSearchFocussed] = React.useState(false);  
  const [searchKeyword, setSearchKeyword] = useState("");
 
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [sidebarStyle, setSidebarStyle] = useState({}); 
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
    
  // const handleBackClick = () => setSidebarVisible(!sidebarVisible);
    
  // const handleConversationClick = useCallback( () => {
  
  //   if ( sidebarVisible ) {
  //     setSidebarVisible(false);
  //   }
  
  // }, [sidebarVisible, setSidebarVisible] );
    
  useEffect(() => {
  
    if (!params?.chatId ) {
  
      setSidebarStyle({
        display:"flex",
        flexBasis: "auto",
        width:"100%",
        maxWidth: "100%"
      });
  
      setConversationContentStyle({
        display:"flex"
      });
  
      setConversationAvatarStyle({
        marginRight: "1em"
      });
   
    } 
    else if(params?.chatId &&isMobile){
      setSidebarStyle({
        display:"none",});
      setConversationContentStyle({});
      setConversationAvatarStyle({}); 
    }
    else {

      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({}); 
    }
  
  }, [params?.chatId]);
// }, [params,sidebarVisible   ]);
    
  const { data: profileSearchResult, isFetched:isProfileSearchFetched  } = useQuery({
    queryKey: ["searchProfile",searchKeyword],
    queryFn: async () => { 
      let c=[]
      if(searchKeyword!=""){
        c= await CommonApiServices?.searchProfileByKeyword(searchKeyword)
      } 
      return c
    },
    initialData:[]
  });   
 
  
  const searchedProfileListComp = () => {
    return (<>
    
    <Box m={2}>
      <List  px={2}>
         {(isProfileSearchFetched)?<>
            
          {profileSearchResult.map((resultData) => {
            let chatD={id:"new",ChatTypeId:1,ProfileId:resultData?.id}
            resultData?.participants?.forEach(chatDD => {
              chatD=chatDD
            });

            if(resultData.firstName||resultData.profileName||resultData.userName){
            return  <ListItem 
                      onClick={() => { 
                        setIsSearchFocussed(false)
                        navigate("/chats/"+chatD?.ChatTypeId+"/"+chatD?.id+"/"+chatD?.ProfileId);
                      }}
                      disablePadding 
                    >
                    <ListItemAvatar>
                      <Avatar src={resultData?.ProfileImages?.length?
                  ((CommonServices?.getPostAttachments({attachments:resultData?.ProfileImages,thumbnail:true,firstUrl:true})))
                     :resultData?.profileImageUrl} />
                    </ListItemAvatar>
      
                    <ListItemText
                            primary={resultData.firstName?(resultData.firstName+" "+resultData.lastName):resultData.profileName}
                            secondary={""}
                    />
               

                {/* <ListItemButton
                  onClick={() => { 
                    setIsSearchFocussed(false)
                    navigate("/chats/"+chatD?.ChatTypeId+"/"+chatD?.id+"/"+chatD?.ProfileId);
                  }}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary={resultData.firstName?(resultData.firstName+" "+resultData.lastName):resultData.profileName} />
                </ListItemButton> */}
              </ListItem>
             }})} 
          </>: <> 
            
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Skeleton animation="wave" variant="circular" width={40} height={40} /> 
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={ <Skeleton animation="wave" height={10}   /> } 
              secondary={ <Skeleton animation="wave" height={10}  /> } />
            </ListItem>
            </>}
            </List>
        </Box> 
    </>)

  }

  function ConversationComp(props){
   const [chat,setChat]=useState(props?.chat)
   const [conversationD,setConversationD]=useState({}) 
  //  dayjs.extend(calendar) 
  dayjs.extend(relativeTime)
    useEffect(() => {
        if(chat){
        let lastMessageSenderProfile={}
        let lastMessage=chat?.Messages[chat?.Messages?.length-1]//chat?.Messages[0]
        let lastActivityTime=  dayjs(lastMessage?.createdAt).fromNow(true) 
        try { 
          //  let lastActivityTime=  lastMessage?.createdAt 
          let isYourMessage= false
          if(lastMessage?.ProfileId==myCurrentProfile?.id){
            isYourMessage=true
          }

          if(isYourMessage){
              lastMessageSenderProfile=myCurrentProfile
            }
            else{
              chat?.participants?.forEach(participantD => {
                if(participantD?.id==lastMessage?.ProfileId){
                  lastMessageSenderProfile=participantD
                } 
              }
              ); 
            }
            let conversationSecondPerson={ 
              name:chat?.name||"group" ,  
              // src:lastMessageSenderProfile?.profileImageUrl ,
            }
            if(chat?.ChatTypeId==1){ 
              chat?.participants?.forEach(participantDD => {
                if(participantDD?.id!=myCurrentProfile?.id){  
                  conversationSecondPerson.name=CommonServices?.getProfileName(participantDD)||""
                  conversationSecondPerson.src=participantDD?.ProfileImages?.length?
                  ((CommonServices?.getPostAttachments({attachments:participantDD?.ProfileImages,thumbnail:true,firstUrl:true})))
                     :participantDD?.profileImageUrl
                } 
              })
            }
            setConversationD({
                info: parse(lastMessage?.content||"") ,
                lastSenderName:isYourMessage?"you":null,//conversationSecondPerson.name ,
                name: conversationSecondPerson.name,
                // name:participantD?.firstName ,    
                active: (params?.chatId==chat?.id)?true:false,
                src:conversationSecondPerson?.src ,
                unreadCnt:chat?.unseenMessagesCount,
                unreadDot:isYourMessage?(lastMessage?.seen?false:true):false, 
                lastActivityTime: lastActivityTime ,
                // status="available" ,//||"unavailable"||"dnd"
              }  )
          } catch (error) {
            // console.log(error,"errrrrrrrrrrrrrrrrrrrrrrrrrrrr")
          }

    }
    },[chat])

    

    return <Conversation  
      onClick={()=>{
        navigate("/chats/"+chat?.ChatTypeId+"/"+chat?.id)}
      }
      info={conversationD?.info}
      lastSenderName={conversationD?.lastSenderName}
      name={conversationD?.name}
      active={conversationD?.active}
      unreadCnt={conversationD?.unreadCnt}
      lastActivityTime={ conversationD?.lastActivityTime }
      unreadDot={conversationD?.unreadDot} 
    >
     {chat?.participants && <Avatar
        // name={conversationD?.name}
        src={conversationD?.src}
        // status={conversationD?.status} 
      />}
    </Conversation>
  }

  return ( 
    <>   

<Sidebar position="left" scrollable={false} style={sidebarStyle}
  >
     <AppBar position="static" sx={{ width: "100%", bgcolor: "background.paper" }}>
              {!isSearchFocussed?<Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    try { 
                      if(props?.onClose){
                       props?.onClose();
                      }
                      else{
                        navigate(-1)
                      }
                    } catch (error) {
                      
                    }
                  }}
                >
                  <ArrowBack/>
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {"Chats"}
                </Typography>
              </Toolbar>:
              <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => { 
                    setIsSearchFocussed(false) 
                }}
              >
                <ArrowBack/>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {"Search"}
              </Typography>
            </Toolbar>}
              <Divider sx={{ my: "0rem" }} />
            </AppBar>

   {<Search onFocus={()=>setIsSearchFocussed(true)} placeholder="Search..." 
   
   onChange={(v)=>{
      // searchProfile(v)   
        setSearchKeyword(v)
        queryClient.invalidateQueries({ 
          queryKey: ["searchProfile",v ],
      })
    }}/> }
 
    {isSearchFocussed?searchedProfileListComp(): <ConversationList>
      
      {chats?.map((chat)=><ConversationComp chat={chat}/>) }
            
        </ConversationList>}

  </Sidebar> 
      {forceRerender} 
    </>  
  );
}

export default ChatsList;
