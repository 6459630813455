// @mui material components
import Container from "@mui/material/Container";
import React, { useEffect } from "react";
import imageCompression from "browser-image-compression";
// Images
// import bgImage from "assets/images/bg-rental.jpeg";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import {
  AppBar,
  Avatar, 
  Button, 
  Chip, 
  DialogActions, 
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ArrowBack,
  Article,
  HistoryTwoTone,
  HomeWork,
  PostAdd,
  QuestionAnswer,
  Send,
  ShoppingBag,
  Work,
} from "@mui/icons-material";
import MKButton from "components/MKButton";
import { isMobile } from "react-device-detect";
import MKEditor from "components/MKEditor";
import MKBox from "components/MKBox";
import { DropzoneArea } from "mui-file-dropzone";
import { makeStyles } from "@mui/styles";
import { HttpService } from "Api-Services/httpService";
import { CommonServices } from "Api-Services/commonServices";
import { toast } from "react-toastify";

const postStylesDropzone = makeStyles((theme) => ({
  root: {
    "& .css-9igssw-MuiDialogContent-root": {
      padding: "5px",
    },
    "& .ql-toolbar.ql-snow ": {
      padding: "0px",
    },
  },
  dropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "30px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  storyDropzoneClass: {
    // maxHeight: "124px  !important",
    minHeight: "5px !important",
    "& .css-f8f0pk-MuiSvgIcon-root ": {
      height: "130px",
      width: "30px",
    },
    "& .css-1g96e5k-MuiTypography-root": {
      fontSize: "0.9rem",
      fontWeight: "700",
      marginTop: "4px",
      marginBottom: "2px",
    },
  },
  postTextArea: {
    "& .ql-editor ": {
      minHeight: "120px",
    },
  },
  storyTextArea: {
    "& .ql-editor ": {
      minHeight: "60px",
    },
  },
}));
 
function CreateNewPost(props) {
  const postStyles = postStylesDropzone();
  const [currentData] = useCurrentUser();
  const params = useParams();
  const { myCurrentProfile, currentUser, postPrivacies } = currentData;
  const [postPrivacyId, setPostPrivacyId] = React.useState(1);
  const [postTypeId, setPostTypeId] = React.useState(params?.postTypeId||1);
  const [postMessage, setPostMessage] = React.useState("");
  const [postAttachments, setPostAttachments] = React.useState([]);

  useEffect(() => {
    if(params?.postTypeId){
      setPostTypeId(params?.postTypeId)
    }
  }, [params]);

  const createPost = async () => {
    let res = [];
    try {
      let isPostWithAttachment = false;
      let relativePath = "/api/post/newwithattachment";
      let filesData = postAttachments || [];
      console.log("postAttachments",postAttachments)
      let filesDataNew = [];
      let additionalData = [];
      let postData = { 
        message: postMessage,
        PostTypeId: postTypeId,
        PostPrivacyId: postPrivacyId,
      };
      const formData = new FormData();
      const options = {
        maxSizeMB: 0.2, 
        maxWidthOrHeight: 2520, 
        useWebWorker: true,
        fileType: "image/jpeg",
      };
      console.log("filesData",filesData)
      if (filesData) {
        if (filesData.length) {
          relativePath = "/api/post/newwithattachment";
          isPostWithAttachment = true;
          for (let index = 0; index < filesData.length; index++) {
            const imageFile = filesData[index];
            const compressedFile = await imageCompression(imageFile, options);
            filesDataNew.push(compressedFile);
          }
          let filePath = "./public/files/post_files";
          const aa = _.filter(filesDataNew, (file) => {
            console.log("file",file) 
            formData.append("file", file,file.name);
            formData.append("fileTypeExtension", ".jpg");
            formData.append("folderPath", filePath);
            return file;
          });

          let postDataRes = Object.entries(postData);
          postDataRes.forEach((element) => {
            formData.append(element[0], element[1]);
          }); 
          additionalData = {
            headers: {
              "Content-Type": "multipart/form-data",
            }, 
          };
        }
      } 
      const data = isPostWithAttachment ? formData : postData;
      let toastId = toast.loading("posting...",{
        position: toast?.POSITION.BOTTOM_LEFT
      })   
      let postFeedRes = await postDocuments("uploadFile", relativePath, data, additionalData);
      toast?.update(toastId, { render: postFeedRes.success?"posted":"try again", type: postFeedRes.success?"success":"warn", isLoading: false , autoClose: 2000});
      console.log("uploadFiles  ", postFeedRes, res);
      props?.onClose();
    } catch (err) {
      console.log("uploadFiles err", err);
    } 
  };

  const handleFileChange = async (filesData) => {
    try {
      if (filesData) {
        if (filesData.length) {
          setPostAttachments(filesData);
        }
      }
    } catch (error) {
      console.log("handleFileChange err", error);
    }
  };

  const postDocuments = async (documentName, relativePath, data, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    documentsResponse = await HttpService.postService(relativePath, data, additionalData || {}, "");

    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true;
      let record = responseD.record;

      //write codtions down here//
    } else {
      responseD.record = documentsResponse.data;
    }
    return responseD;
  };

  const feedPostBox=()=>{


    return <>
    
    <MKBox spacing="2" mb={1} className={postStyles.postTextArea}>
          {" "}
          <MKEditor
            onChange={(e) => {
              console.log("eee", e);
              setPostMessage(e);
            }}
          />
        </MKBox>
        <MKBox spacing="2" mb={1}>
          <DropzoneArea
            showAlerts={["error", "info"]}
            showPreviews
            filesLimit={10}
            maxFileSize={25000000}
            showPreviewsInDropzone={false}
            dropzoneClass={postStyles.dropzoneClass}
            acceptedFiles={["image/*"]}
            dropzoneText={
              isMobile ? "Add Photo/Video" : "Drag and drop an image/video here or click"
            }
            // onChange={(files) => console.log("Files:", files)}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </MKBox>
    </>
  }
   
  return (
    <div className={postStyles.root}>
      {/* <Dialog open={open} onClose={() => {
          setOpen(false);
        }}> */}

      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              props?.onClose();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
            Create 
            {(postTypeId==1)?" Feed Post":" "} 
            {(postTypeId==4)?" Market PLace Post":" "} 
            {(postTypeId==2)?" Story ":" "}  
             
          </Typography> 
          {(postMessage||(postAttachments?.length))? ( 
            <MKButton
              size="small"
              color="success"
              onClick={() => {
                createPost();
              }}
              startIcon={<Send />}
            >
              Post
            </MKButton>
          ):null}
        </Toolbar>
        <Divider sx={{ my: "0rem" }} />
      </AppBar>
      {/* </Box> */}
      {/* <DialogTitle>Create Post</DialogTitle> */}

      {/* <Container sx={{px:0,py:isMobile?"3px":"20px"}}>  */}
      
    <List sx={{ mx:isMobile ?"1px":"12px",
            width: "100%",
            py: isMobile ? "8px" : "20px",
            px: "12px",
            bgcolor: "background.paper",
          }}
        >
          <ListItem
          // secondaryAction={
          //   <IconButton onClick={handleClickOpen} edge="end" aria-label="delete">
          //     <PostAdd />
          //   </IconButton>
          // }
          >
            <ListItemAvatar>
              <Avatar  src={
                  myCurrentProfile
                    ?(
                      myCurrentProfile?.ProfileImages?.length?
                  ((CommonServices?.getPostAttachments({attachments:myCurrentProfile?.ProfileImages,thumbnail:true,firstUrl:true})))
                     :myCurrentProfile?.profileImageUrl)
                    : ""
                }></Avatar>
            </ListItemAvatar>
            <ListItemText
              // onClick={handleClickOpen}
              primary={CommonServices.getProfileName(myCurrentProfile)}
              // primary="What is on your mind?"
              secondary={
                (postPrivacies ? (postPrivacies.length ? true : false) : false) ? (
                  <FormControl  variant="filled" sx={{}}>
                    {/* <InputLabel id="demo-simple-select-label">Privacy</InputLabel> */}
                    <Select
                      // label="Privacy"
                      value={postPrivacyId}
                      onChange={(e) => setPostPrivacyId(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {postPrivacies.map((privacyData) => (
                        <MenuItem value={privacyData?.id}>{privacyData?.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null
              }
            />
          </ListItem>
        </List>
      <DialogContent sx={{ mx:isMobile ?"1px":"12px"}} > <>
      { feedPostBox() 
      }</> 
        {/* </Container> */}


        {/* <DialogContentText>Select post type</DialogContentText> */}
        {/* <Box> */}
        {/* <DialogTitle>Select post type</DialogTitle> */}

        <MKBox my={4}>
          <Divider sx={{ my: "0rem" }} />
          <Typography variant="h6" my={2}>
            Change post type
          </Typography>

          <Grid
            container
            spacing={1}
            // columns={6}
          >
            <Grid item>
              <Chip
                icon={<PostAdd sx={{ fontSize: 40 }} />}
                variant={(postTypeId==1)?"contained":"outlined"}
                onClick={()=>setPostTypeId(1)}
                // fullWidth
                color="success"
                label= {"Feed"}
                /> 
            </Grid>
            <Grid item>
              <Chip
                icon={<HistoryTwoTone sx={{ fontSize: 40 }} />}
                label= {"Story"}
                variant={(postTypeId==2)?"contained":"outlined"}
                onClick={()=>setPostTypeId(2)} 
                // fullWidth
                color="success"
                // style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
             /> 
            </Grid>
            <Grid item>
              <Chip
                label= {"Article"}
                icon={<Article sx={{ fontSize: 40 }} />}
               component={Link}
               to="/create/create-article"
                variant={(postTypeId==7)?"contained":"outlined"}
                // fullWidth
                color="success"
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              /> 
            </Grid>
            <Grid item>
              <Chip
                icon={<QuestionAnswer sx={{ fontSize: 40 }} />}
               component={Link}
               variant={(postTypeId==8)?"contained":"outlined"}
               to="/create/create-discussion"
                // fullWidth
                color="success"
                label= {"Discussion"}
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              /> 
            </Grid>
            <Grid item>
              <Chip
                icon={<Work />}
                variant={(postTypeId==3)?"contained":"outlined"}
                component={Link}
                onClick={()=>props?.onClose()}
                to={"/pages/posts/create-job-post"}
                color="success"
                //  
                label={"Job"}
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              /> 
            </Grid>
            <Grid item>
              <Chip
                icon={<ShoppingBag />}
                component={Link}
                variant={(postTypeId==4)?"contained":"outlined"}
                onClick={()=>props?.onClose()}
                color="success"
                label={"Market Place"}
                to={"/create/Create-Market-Place"} 
                style={{ backgroundColor: "rgba(240,242,245,1.0)" }}
              /> 
            </Grid> 
          </Grid>
        </MKBox>
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        <MKButton
          onClick={() => {
            props?.onClose();
          }}
        >
          Cancel
        </MKButton>
      </DialogActions>
      {/* </Dialog>  */}
    </div>
  );
}

export default CreateNewPost;
