import { HttpService } from "../Api-Services/httpService";
import { createContext, useEffect, useState, useContext } from "react";
import { DataManagementService } from "../Api-Services/dataManagementService";
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from "Api-Services/useAuth";
import ReactGA from "react-ga4";
import { CommonServices } from "Api-Services/commonServices";
// home-banner
import homeBanner from "assets/images/examples/home-banner.jpg";
import aboutUsBanner from "assets/images/examples/about-us.jpg"; //aboutUsCard.jpg
import aboutUsCard from "assets/images/aboutUsCard.jpg"; //aboutUsCard.jpg
import queryString from "query-string";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import { CommonApiServices } from "Api-Services/commonApiServices";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { requestPermission } from "Api-Services/firebase";
const CurrentUserContext = createContext();
//now we have a context object
var arraySort = require('array-sort');
 
const endpoint = HttpService?.getHeaderAndURL()?.baseUrl; 
function CurrentUserProvider(props) {
  //create the provider and its functionality
  const [currentGlobalDialogBoxData, setCurrentGlobalDialogBoxData] = useState({
    dialogId: "",
    data: null,
  });
  const params=useParams()
  const queryClient = useQueryClient()
  const [currentUser, setCurrentUser] = useState(null);
  const [hideMobileHeaderNav, setHideMobileHeaderNav] = useState(false);
  const [hideMobileFooterNav, setHideMobileFooterNav] = useState(false);
  const [myCurrentProfile, setMyCurrentProfile] = useState(null);
  const [myCurrentProfileLoading, setMyCurrentProfileLoading] = useState(false);
  const [myCurrentUserLoading, setMyCurrentUserLoading] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [browserPushNotificationSubscribed, setBrowserPushNotificationSubscribed] = useState(false);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [employmentJobLocationTypes, setEmploymentJobLocationTypes] = useState([]);
  const [employmentSalaryDealTypes, setEmploymentSalaryDealTypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [websiteFiles, setWebsiteFiles] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [jobs, setJobs] = useState([]); //
  const [postPrivacies, setPostPrivacies] = useState([]); //
  const [myAgencies, setMyAgencies] = useState([]);
  const [myFollowings, setMyFollowings] = useState([]);
  const [myFriendships, setMyFriendships] = useState(null);
  const [myProfiles, setMyProfiles] = useState([]);
  const [myFeedPosts, setMyFeedPosts] = useState([]);
  const [explorePosts, setExplorePosts] = useState([]);
  const [explorePostsLoading, setExplorePostsLoading] = useState(false);
  const [myJobVacancyPosts, setMyJobVacancyPosts] = useState([]);
  const [myJobApplications, setMyJobApplications] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [popularEmploymentAgencies, setPopularEmploymentAgencies] = useState([]);
  const [popularEmploymentAgenciesLoading, setPopularEmploymentAgenciesLoading] = useState(false);
  const [employmentAgencyPaymentAccounts, setEmploymentAgencyPaymentAccounts] = useState([]);
  const [employmentAgencyJobVacancies, setEmploymentAgencyJobVacancies] = useState([]);
  const [recentEmploymentJobVacancies, setRecentEmploymentJobVacancies] = useState([]);
  const [recentEmploymentJobVacanciesLoading, setRecentEmploymentJobVacanciesLoading] =
    useState(false);
  const [newJobSeekers, setNewJobSeekers] = useState([]);
  const [newJobSeekersLoading, setNewJobSeekersLoading] = useState(true);
  const [agencyNewJobSeekers, setAgencyNewJobSeekers] = useState([]);
  const [myJobSeekerInformation, setMyJobSeekerInformation] = useState(null);
  const [myAgencyJobSeekerInformation, setMyAgencyJobSeekerInformation] = useState(null);
  const [myJobSeekerAndAgencies, setMyJobSeekerAndAgencies] = useState([]);
  const [isAgentWebsite, setIsAgentWebsite] = useState(false);
  const [agencyData, setAgencyData] = useState(null);

  const [forceRerender, setForceRerender] = useState(true);
  const websitePagesContentD = {
    pages: {
      joinUsPage: {
        sections: {
          instruction: {
            title: "instructions",
            body: `<p>
            ONLINE REGISTRATION INSTRUCTIONS
            Note: The majority of returning students are eligible to use online registration. You are eligible unless you fall within
            any of the following categories:
            1) You are a new job seeker.
            2) You are a returning seeker who must reapply because of a lapse in continuous enrollment.
            3) You have earned less than 12 hours of college credit.
            4) You have a 1005–General College major AND have 30 or more hours of college credit. You may register online
            once you declare a specific major. Contact the Admissions Office to declare a specific major.
            5) You are non-degree seeking.
            6) You need to enroll in college preparatory/remedial courses.
            7) Your cumulative GPA is less than 2.00.
            8) You are a dual enrolled or early admission student.
            9) You are a clock-hour student (automotive, cosmetology, law enforcement, CNA, etc.)
            10) You have a hold on your record.
            11) You wish to take advantage of a state employee fee waiver.</p>
            `,
          },
          card: {
            cardImage:
              "https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80",
          },
        },
      },
      homePage: {
        sections: {
          banner: {
            backgroundImage: homeBanner,
            title: "",
            // description:
            //   " The time is now for it be okay to be great. People in this world shun people for being nice.",
          },
        },
      },
      contactUsPage: {
        sections: {
          card: {
            image: null,
            title: "",
            description: "",
          },
          formSection: {
            note: `For further questions,, please contact using our contact form.`,
          },
        },
      },
      aboutUsPage: {
        sections: {
          banner: {
            backgroundImage: aboutUsBanner,
            title: "",
            // description:
            //   "We are constantly trying to express ourselves and actualize our dreams. If you  have the opportunity to play this game",
          },
          companyDescription: {
            title: "The BAALLY JOBS Platform",
            description: ` 
            <article> 
          
              <p>In today's dynamic job market, BAALLY JOBS has redefined the hiring landscape, bridging the gap between employers and job seekers. This brief explores the platform's evolution, highlighting its solutions to common recruitment challenges.</p>
          
              <p>BAALLY JOBS is a virtual nexus reshaping the recruitment process. It eliminates geographical constraints, connecting employers with a diverse global talent pool while empowering job seekers to explore opportunities beyond local boundaries.</p>
          
              <p>The platform's advanced features, including robust filters and algorithms, streamline hiring. Employers efficiently find candidates, and job seekers receive personalized recommendations based on their profiles and preferences. Incorporating AI and machine learning, BAALLY JOBS ensures a data-driven, strategic recruitment process.</p>
          
              <p>While offering efficiency, BAALLY JOBS prioritizes user experience. Its user-friendly interface and personalized recommendations address the potential overwhelming nature of job postings. Additionally, the platform is committed to eliminating algorithmic bias, fostering a fair and inclusive environment.</p>
          
              <p>In conclusion, BAALLY JOBS stands at the forefront of modern recruitment, providing tailored solutions for employers and job seekers. Through global reach, advanced features, and a commitment to inclusivity, BAALLY JOBS is transforming the employment landscape, promising a more connected and equitable professional future.</p>
            </article> `,
          },
          card: {
            image: aboutUsCard,
            description: `"Hire or Get Hired on our online platform involves a seamless and efficient process for both employers and job seekers. Employers can post job openings, define criteria, and review applicants, while job seekers can create profiles, apply for positions, and showcase their skills. Our platform facilitates a connection between talent and opportunities, streamlining the hiring process for employers and providing a user-friendly interface for individuals seeking their next career move."`,
            title: "Get insights on Search",
          },
          // teams: [
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          //   {
          //     image: "https://wfcsradio.com/wp-content/uploads/2019/08/team-member-01.jpg",
          //     name: "Alec Thompson",
          //     profession: "CEO / Co-Founder",
          //     description:
          //       "And I love you like Kanye loves Kanye. We need to restart the human foundation.",
          //   },
          // ],
        },
      },
      FAQPage: {
        sections: {
          header: {
            title: "",
            description: "",
          },
          faq: [],
        },
      },
      footerPage: {
        sections: {
          socialMedias: [
            {
              icon: "facebook",
              link: "https://www.facebook.com/BFamilyPromotion",
            },
            {
              icon: "YouTube",
              link: "https://www.youtube.com/channel/UCBdLexsyb4xyeA7XPAOyqEw",
            },
          ],
        },
      },
      testimonialsPage: {
        sections: {
          header: {
            title: "Some thoughts from our clients",
            description: ` If you are selected for them you&apos;ll also get three tickets, opportunity to
                    access Investor Office Hours and Mentor Hours and much more all for free.
                 `,
          },
          testimonials: [
            {
              image: "",
              name: "Biniam Asefa",
              role: "Tutor",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
            {
              image: "",
              name: "Kibrom Ayele",
              role: "trainee parent",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
            {
              image: "",
              name: "Meron Kidane",
              role: "trainee",
              review:
                "The connections you make at Web Summit are unparalleled, we met users all over the world.",
            },
          ],
        },
      },
    },
  };
  const [agencyWebsitePagesContent, setAgencyWebsitePagesContent] = useState(null);
  const [websitePagesContent, setWebsitePagesContent] = useState(websitePagesContentD);
  const [socket, setSocket] = useState(null);
  const [connectedSocket, setConnectedSocket] = useState(null);
  // const [notifications,setNotifications] = useState([]);
  const [notificationsCount,setNotificationsCount] = useState(0); 
  const [chatsCount,setChatsCount] = useState(0);
 

  useEffect(async () => {
    // console.log("location", location);
    try { 
      await getStarted(); 
    } catch (error) {
      // console.log("error", error);
    }
  }, []);
  const { data: chats,isChatsFetched  } = useQuery({
    queryKey: ["getMyChats" ],
    queryFn: async () => { 
      // console.log("getMyChats", )
      let c=[]
      let mm= await CommonApiServices.getMyChats()
      try { 
      if(mm?.length){
        mm= arraySort(mm,"lastMessageDate" , {reverse: true}) //
        c=mm
      }
        // console.log("lastMessageDate",mm)
        // console.log("chats",c)
      } catch (error) {
        // console.log("chats error",error)
        
      }
         return c
    },
    initialData:[]
  });
  
  // mynotifications
  const { data: notifications, isFetched :isNotificationsFetched  } = useQuery({
    queryKey: ["/api/notification/mynotifications" ],
    queryFn: async () => { 
      // console.log("getMyChats", )
      let c=[]
      let mm= await HttpService.getApiData("/api/notification/mynotifications" );
    
      try { 
      if(mm?.length){
        // mm= groupNotifications(mm)//
        c=mm
      }
        // console.log("lastMessageDate",mm)
        // console.log("chats",c)
      } catch (error) {
        // console.log("chats error",error)
        
      }
         return c
    },
    initialData:[]
  });
  
  
useEffect(async () => { 
  try {
    if(myCurrentProfile){
      let socketD=io(endpoint, {
        transports: ["websocket", "polling"],
        withCredentials: true
      })
      setSocket(socketD) 
    }
  } catch (error) {
    console.log("error", error);
  }
}, [myCurrentProfile]);

  
useEffect(async () => { 
  try { 
    if(socket){
    socket.emit("setup",myCurrentProfile)
    socket.on("connection",()=>{ 
      console.log("connection setup", )
      setConnectedSocket(true)
    }) 
    socket.on("message recieved",(newMessage)=>{ 
      // console.log("message recieved", )
      queryClient.invalidateQueries({  
          queryKey: ["getMyChats" ],
      })
      if(params?.chatId==newMessage?.Chat?.id){
        }
      else{
      setChatsCount(chatsCount+1)
      }
    })  
   }
  } catch (error) {
    // console.log("error", error);
  }
}, [socket]);



  const getStarted = async () => {
    try {
      setMyCurrentUserLoading(true);
      let isUserLoggedInD = await UseAuth.verifyUser();
      // console.log("isUserLoggedInD ", isUserLoggedInD);
      setMyCurrentUserLoading(false);
      // setMyCurrentProfileLoading(false);
      if (isUserLoggedInD) {
        getMyCurrentProfile();
      }
      setIsUserLoggedIn(isUserLoggedInD);
      ////////////////////////////// 
      getEmploymentSalaryDealTypes();
      getEmploymentJobLocationTypes();
      getEducationLevels();
      getEmploymentTypes();
      getJobs();
      getPostPrivacies();
      //////////////////////////////

      if (isUserLoggedInD) {
        getCurrentUser();
        // getMyAgencies();
        // getMyFollowings();
        // getMyFriendships();
        getMyProfiles();
        // getMyFeedPosts();
        // getMyJobVacancyPosts();
        // getMyJobApplications();
        // getUserFiles();
      }

      // getPopularEmploymentAgencies();
      // getNewJobSeekers();
      // getRecentEmploymentJobVacancies();
      // await getAgentDataForWebsite_byWebsiteDomain();
      //  agencyDataD2=await getAgentDataForWebsite_byId(agentId)
      let GAMeasurementId = "G-9WH6WP5G6X";//G-9WH6WP5G6X //Baally jobs GA ID////G-9WH6WP5G6X
      if (GAMeasurementId) {
        ReactGA.initialize(GAMeasurementId);
      }
    } catch (error) {}
  };

  const updateCurrentGlobalDialogBoxData = (dialogData) => {
    let dataD = { dialogId: "", data: null };
    try {
      if (dialogData) {
        if (dialogData.dialogId) {
          if (dialogData.data) {
            dataD = dialogData;
            setCurrentGlobalDialogBoxData(dataD);
          }
        }
      }
    } catch (error) {
      // console.log("updateCurrentGlobalDialogBoxData error", error);
    }
    setForceRerender(!forceRerender);
  };

  const getCurrentUser = async () => {
    let mydetails = null;
    // setMyCurrentUserLoading(true);
    await getDocumentsAll("mydetails", "/api/auth/mydetails").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
    setCurrentUser(mydetails);
    // setMyCurrentUserLoading(false);
  };
 
  const getMyCurrentProfile = async (agencyDataDDD) => {
    setMyCurrentProfileLoading(true);
    let mydetails = null;
    await getDocumentsAll("myjobseekerinformation", "/api/profile/myprofile").then(
      (mydetailsRes) => {
        if (mydetailsRes.success) {
          if (mydetailsRes.record) {
            mydetails = mydetailsRes.record;
          }
        }
      }
    );
    setMyCurrentProfile(mydetails);
    setMyCurrentProfileLoading(false);
    
    if(mydetails){
      let pushRes=await UseAuth.verifyPushNotificationToken()
      if(pushRes){ 
       setBrowserPushNotificationSubscribed(true);
      }
      else{
       setBrowserPushNotificationSubscribed(false);
       requestPermission() 
      }
    }
  };
 
  const getMyProfiles = () => {
    try {
      let mydetails = [];
      getDocumentsAll("myjobseekerinformation", "/api/profile/myprofiles").then((mydetailsRes) => {
        if (mydetailsRes.success) {
          if (mydetailsRes.record) {
            mydetails = mydetailsRes.record;
          }
        }
        if (mydetails.length) {
          mydetails.reverse();
        }
        setMyProfiles(mydetails);
      });
    } catch (error) {
      // console.log(error);
    }
  };
  

  const getEmploymentTypes = () => {
    try {
      let mydetails;
      getDocumentsAll("mydetails", "/api/employmenttype/all").then((mydetailsRes) => {
        if (mydetailsRes.success) {
          mydetails = mydetailsRes.record;
          setEmploymentTypes(mydetails);
        } else {
          setEmploymentTypes([]);
        }
        // console.log( mydetails, "setEmploymentTypes  setEmploymentTypes")
      });
    } catch (error) {}
  };
 
  const getEmploymentSalaryDealTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentsalarydealtype/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentSalaryDealTypes(mydetails);
      } else {
        setEmploymentSalaryDealTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };

  const getEmploymentJobLocationTypes = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/employmentJobLocationType/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEmploymentJobLocationTypes(mydetails);
      } else {
        setEmploymentJobLocationTypes([]);
      }
      // console.log( mydetails, "employmentSalaryDealTypes    ")
    });
  };

  const getEducationLevels = () => {
    let mydetails;
    getDocumentsAll("mydetails", "/api/educationLevel/all").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setEducationLevels(mydetails);
      } else {
        setEducationLevels([]);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };

  const getJobs = () => {
    getDocumentsAll("job", "/api/job/all").then((websiteUserTypesRes) => {
      //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
      if (websiteUserTypesRes.success) {
        if (websiteUserTypesRes.record) {
          setJobs(websiteUserTypesRes.record);
        }
      } else {
        setJobs([]);
      }
    });
  };

  const getPostPrivacies = () => {
    getDocumentsAll("postPrivacies", "/api/postprivacy/all").then((websiteUserTypesRes) => {
      //  console.log("postPrivacies",websiteUserTypesRes)
      if (websiteUserTypesRes.success) {
        if (websiteUserTypesRes.record) {
          setPostPrivacies(websiteUserTypesRes.record);
        }
      } else {
        setPostPrivacies([]);
      }
    });
  };
 

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };

    try {
      let documentsResponse = { success: false };
      ////////// UPDATED/////////////
      documentsResponse = await HttpService.getService(
        relativePath,
        additionalData ? additionalData : {},
        ""
      );
      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
      if (documentsResponse.success) {
        responseD.success = true;
      }
      setForceRerender(!forceRerender);
    } catch (error) {
      // console.log(error);
    }
    return responseD;
    ////////// UPDATED/////////////
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    try {
      let documentsResponse = { success: false };
      // setLoading(true);

      ////////// UPDATED/////////////
      documentsResponse = await HttpService.postService(relativePath, data, {}, "");

      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
      if (documentsResponse.success) {
        responseD.success = true;
      }
      setForceRerender(!forceRerender);
    } catch (error) {}
    return responseD;
    ////////// UPDATED/////////////
  };

  async function updateCurrentUser(variableName, data) {
    if (variableName) {
      if (variableName == "hideMobileFooterNav") {
        setHideMobileFooterNav(data);
      }
      if (variableName == "hideMobileHeaderNav") {
        setHideMobileHeaderNav(data);
      }
      if (variableName == "currentGlobalDialogBoxData") {
        updateCurrentGlobalDialogBoxData(data);
      }
      if (variableName == "currentUser") {
        getCurrentUser();
      } 
      if (variableName == "notificationsCount") {
        setNotificationsCount(data);
      }
      if (variableName == "chats") {
          queryClient.invalidateQueries({  
            queryKey: ["getMyChats" ],
        })
      } 
      if (variableName == "chatsCount") {
        setChatsCount(data);
      } 
      if (variableName == "myCurrentProfile") {
        getMyCurrentProfile();
      } 
      if (variableName == "browserPushNotificationSubscribed") {
        getMyCurrentProfile();
      }   
      if (variableName == "myProfiles") {
        getMyProfiles();
      }     
      if (variableName == "employmentTypes") {
        getEmploymentTypes();
      }
      if (variableName == "employmentJobLocationTypes") {
        getEmploymentJobLocationTypes();
      }
      if (variableName == "employmentSalaryDealTypes") {
        getEmploymentSalaryDealTypes();
      }
      if (variableName == "jobs") {
        getJobs();
      }
      if (variableName == "myJobSeekerInformation") {
        getMyCurrentProfile();
      }
    } else {
      getStarted();
    }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite")
  }

  return (
    <CurrentUserContext.Provider
      value={[
        {
          hideMobileHeaderNav: hideMobileHeaderNav,
          hideMobileFooterNav: hideMobileFooterNav,
          socket: socket,
          connectedSocket: connectedSocket,
          chats: chats,
          chatsCount: chatsCount,
          notificationsCount: notificationsCount,
          // notifications: notifications ,
          currentGlobalDialogBoxData: currentGlobalDialogBoxData,
          paymentMethods: paymentMethods,
          employmentAgencyPaymentAccounts: employmentAgencyPaymentAccounts,
          employmentAgencyJobVacancies: employmentAgencyJobVacancies,
          recentEmploymentJobVacancies: recentEmploymentJobVacancies,
          recentEmploymentJobVacanciesLoading: recentEmploymentJobVacanciesLoading,
          myAgencies: myAgencies,
          myProfiles: myProfiles,
          myFollowings: myFollowings,
          myFriendships: myFriendships,
          myFeedPosts: myFeedPosts,
          explorePosts: explorePosts,
          explorePostsLoading: explorePostsLoading,
          myJobVacancyPosts: myJobVacancyPosts,
          myJobApplications: myJobApplications,
          myAgencyJobSeekerInformation: myAgencyJobSeekerInformation,
          myCurrentProfile: myCurrentProfile,
          myCurrentProfileLoading: myCurrentProfileLoading,
          myCurrentUserLoading: myCurrentUserLoading,
          myJobSeekerAndAgencies: myJobSeekerAndAgencies,
          newJobSeekers: newJobSeekers,
          newJobSeekersLoading: newJobSeekersLoading,
          agencyNewJobSeekers: agencyNewJobSeekers,
          websitePagesContent: websitePagesContent,
          agencyWebsitePagesContent: agencyWebsitePagesContent,
          isAgentWebsite: isAgentWebsite,
          agencyData: agencyData,
          educationLevels: educationLevels,
          employmentSalaryDealTypes: employmentSalaryDealTypes,
          popularEmploymentAgencies: popularEmploymentAgencies,
          popularEmploymentAgenciesLoading: popularEmploymentAgenciesLoading,
          employmentTypes: employmentTypes,
          employmentJobLocationTypes: employmentJobLocationTypes,
          isUserLoggedIn: isUserLoggedIn,
          browserPushNotificationSubscribed: browserPushNotificationSubscribed,
          currentUser: currentUser,
          userFiles: userFiles,
          jobs: jobs,
          postPrivacies: postPrivacies,
          websiteFiles: websiteFiles,
          forceRerenderContext: forceRerender,
          myJobSeekerInformation: myJobSeekerInformation,
        },
        updateCurrentUser,
      ]}
      {...props}
    />
  );
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try {
    const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
    // if (!context) throw new Error('Not inside the Provider');
    return context; // [fav, updateFav]
  } catch (err) {}
}

export { useCurrentUser, CurrentUserProvider };
