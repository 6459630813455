import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  SwipeableDrawer,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { UseAuth } from "Api-Services/useAuth";
import {
  Add,
  ArrowBack,
  Group,
  GroupWorkSharp,
  Login,
  Logout,
  Mail,
  MenuRounded,
  Notifications,
  Person,
  SettingsApplications,
  Work,
} from "@mui/icons-material";
import { CommonServices } from "Api-Services/commonServices";

import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { DataManagementService } from "Api-Services/dataManagementService";
import { green, grey } from "@mui/material/colors";
import MKButton from "components/MKButton";
import SearchBoxContent from "examples/Navbars/DefaultNavbar/SearchBoxContent";

// import logo from "assets/logos/logoBF.png";
// import logo from "assets/logos/logoBF2.png";
import logo from "assets/logos/logoBF1.png";
import SwitchMyProfilesBox from "pages/UserProfile/Sections/SwitchMyProfilesBox";
import MySideBarContent from "examples/SideBarContent/MySideBarContent";
import GeneralSideBarContent from "examples/SideBarContent/GeneralSideBarContent"; 
import { isMobile } from "react-device-detect";
import CreateNewPost from "pages/Posts/Post/Components/CreatePost/CreateNewPost";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
 
 

export default function MobileNavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [drawer2Opened, setDrawer2Opened] = React.useState(false);
  const [forceRerender, setForceRerender] = React.useState(false);
  const [jobSearchHistories, setJobSearchHistories] = React.useState(["mesfin", "tutor"]);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [currentData, updateCurrentUser] = useCurrentUser();
  const { isUserLoggedIn, hideMobileHeaderNav,chatsCount, notificationsCount, myJobSeekerInformation, myCurrentProfile } = currentData;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  React.useEffect(() => {
    setDrawerOpened(false);
    setDrawer2Opened(false);
  }, [location.pathname]);

  React.useEffect(() => {
    let historiesD = [];

    let jobSearchHistoriesLocalD =
      DataManagementService.getLocalstorageDataSecured("jobSearchHistories");

    if (jobSearchHistoriesLocalD) {
      if (jobSearchHistoriesLocalD.length) {
        historiesD = jobSearchHistoriesLocalD;
      }
    }

    setJobSearchHistories(historiesD);
  }, [isUserLoggedIn]);

  const goToPage = (page) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(page);
  };
 
  const navigate = useNavigate();
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isUserLoggedIn ? (
        <>
          <MenuItem onClick={() => goToPage("/profile?tab=0")}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Person />
            </IconButton>{" "}
            Profile Info
          </MenuItem>
          <MenuItem>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <p>Messages</p>
          </MenuItem>
          <MenuItem>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Notifications</p>
          </MenuItem>

          {/* <Divider /> */}

          {myJobSeekerInformation && (
            <MenuItem onClick={() => goToPage("/profile/my-applications")}>
              <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                <SettingsApplications />
              </IconButton>{" "}
              My Applications
            </MenuItem>
          )}
          {myJobSeekerInformation && (
            <MenuItem onClick={() => goToPage("/profile?tab=1")}>
              <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                <Work />
              </IconButton>{" "}
              My Careers
            </MenuItem>
          )}
          <MenuItem onClick={() => goToPage("/profile?tab=2")}>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <GroupWorkSharp />
            </IconButton>{" "}
            Joined Agencies
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => UseAuth.logout()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </>
      ) : (
        <>
          <Divider />
          <MenuItem
            onClick={() => {
              UseAuth.goToAuthLoginPage();
            }}
          >
            <ListItemIcon>
              <Login fontSize="small" />
            </ListItemIcon>
            Login / Register
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const drawer = (
    <div style={{width:"100%"}}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setDrawerOpened(false);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Menu
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
          </Toolbar>
        </AppBar>
      </Box>

      <div  style={{width:"100%"}} >
        <MySideBarContent />
        <GeneralSideBarContent />
        {isUserLoggedIn && (
          <>
            <Grid container item xs={12} justifyContent="center">
              <MKButton
                fullWidth
                color="secondary"
                onClick={() => UseAuth.logout()}
                startIcon={<Logout />}
              >
                Log Out
              </MKButton>
            </Grid>
          </>
        )}
      </div>
    </div>
  );

  return (
    hideMobileHeaderNav?null:<Box sx={{ flexGrow: 1, mb: 7 }}>
      <HideOnScroll {...props}>
        <AppBar
          color="white"
          //  position="fixed"
        >
          <Toolbar sx={{ px: 0 }} variant="dense">
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                component={Link}
                to={"/"}
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                // onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 40, height: 40 }} src={logo} />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { md: "flex" } }}>
              {myCurrentProfile && (
                <>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                
                onClick={() => {
                  let data = {
                    dialogId: "CreatePostDialogBox",
                    data: {},
                  }; //createPostDialogContent
                  updateCurrentUser("currentGlobalDialogBoxData", data);
                  //   setFriendshipTab("suggestions")
                  //  setDrawerOpened("freindship")
                }}
                color="inherit"
              >
                <Avatar color="primary" sx={{ width: 30, height: 30 }}>
                  <Add color="dark"/>
                </Avatar>
              </IconButton>
                  <IconButton
                    size="large"
                    edge="end" 
                    onClick={() => {
                       updateCurrentUser("notificationsCount",0) 
                       let data = {
                         dialogId: "NotificationsDialogBox",
                         data: {},
                       }; 
                       updateCurrentUser("currentGlobalDialogBoxData", data);
                     //   setFriendshipTab("suggestions")
                     //  setDrawerOpened("freindship")
                   }} 
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                  >
                  <Badge badgeContent={notificationsCount} color="error">
                    <Avatar color="primary" sx={{ width: 30, height: 30 }}>
                      <Notifications color="dark"/>
                    </Avatar>
                    </Badge>
                  </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    onClick={() => {
                      
                      updateCurrentUser("chatsCount",0) 
                     let data = {
                       dialogId: "MessageDialogBox",
                       data: {},
                     }; 
                     updateCurrentUser("currentGlobalDialogBoxData", data);
                     //   setFriendshipTab("suggestions")
                     //  setDrawerOpened("freindship")
                   }}
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                  >
                  <Badge badgeContent={chatsCount} color="error">
                    <Avatar color="primary" sx={{ width: 30, height: 30 }}>
                      <Mail color="dark"/>
                    </Avatar>
                    </Badge>
                  </IconButton>{" "}
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    // aria-controls={menuId}
                    onClick={() => {
                      let data = {
                        dialogId: "FriendshipDialogBox",
                        data: {
                          profile: myCurrentProfile,
                          friendshipTab: "followers",
                        },
                      };
                      updateCurrentUser("currentGlobalDialogBoxData", data);
                    }}
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <Avatar color="primary" sx={{ width: 30, height: 30 }}>
                      <Group color="dark"/>
                    </Avatar>
                  </IconButton>
                </>
              )}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                onClick={() => {
                  setDrawerOpened("search");
                }}
                color="inherit"
              >
                <Avatar color="primary" sx={{ width: 30, height: 30 }}>
                  <SearchIcon color="dark"/>
                </Avatar>
              </IconButton>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                // onClick={handleMobileMenuOpen}

                onClick={() => {
                  let data = {
                    dialogId: "SideBarMenuDialogBox",
                    data: {},
                  };
                  if(isUserLoggedIn){
                    updateCurrentUser("currentGlobalDialogBoxData", data)
                  }
                  else {navigate("/profile")};
                }}
                color="inherit"
              >
                <Avatar
                  color="primary"
                  sx={{ width: 30, height: 30 }}
                  // sx={{ width: 24, height: 24, marginTop: "-13px" }}
                >
                  <MenuRounded alt="Remy Sharp" color="primary" />
                </Avatar>
                {/* <Avatar   sx={{ width: 40, height: 40 }} src={myCurrentProfile?myCurrentProfile.logo:""}/> */}
                {/* <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    //
                    <Avatar color="primary" sx={{ width: 24, height: 24, marginTop: "-13px" }}>
                      <MenuRounded alt="Remy Sharp" color="primary" />
                    </Avatar>
                  }
                >
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt="Travis Howard"
                    src={myCurrentProfile ? myCurrentProfile.logo : ""}
                  />
                </Badge> */}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {renderMobileMenu}
      {renderMenu}

      <SwipeableDrawer
        sx={{
          // zIndex: 1111110,
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "100%",
            backgroundColor: "#f0f2f5",
          },
        }}
        // swipeAreaWidth={100}
        anchor={"left"}
        open={drawerOpened == "mobileMenu"}
        onClose={() => {
          setDrawerOpened(false);
        }}
        onOpen={() => {
          setDrawerOpened("mobileMenu");
        }}
      >
        {drawer}
      </SwipeableDrawer>

      <Dialog
        sx={{ zIndex: 11111 }}
        fullScreen
        open={drawerOpened == "search"}
        onClose={() => {
          setDrawerOpened(false);
        }}
        TransitionComponent={Transition}
      >
        <SearchBoxContent
          onClose={() => {
            setDrawerOpened(false);
          }}
        />
      </Dialog>

      <Dialog 
        open={drawerOpened == "createPostDialogContent"} 
        onClose={() => {
          setDrawerOpened(false);
        }} fullScreen={isMobile ? true : false}>
        <></>
        <CreateNewPost
          onClose={() => {
            setDrawerOpened(false);
          }}
        />
      </Dialog>
      <SwipeableDrawer
        sx={{ zIndex: 1111111 }}
        // container={container}
        anchor="bottom"
        open={drawer2Opened == "switchAgency"}
        onClose={() => setDrawer2Opened(false)}
        // onOpen={()=>setDrawer2Opened("switchAgency")}
        swipeAreaWidth={150}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SwitchMyProfilesBox onClose={() => setDrawer2Opened(false)} />
      </SwipeableDrawer>

      {forceRerender}
    </Box>
  );
}

MobileNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
