import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
var CryptoJS = require("crypto-js");
import parse from "html-react-parser";
import { HighQuality } from "@mui/icons-material";
var encryptionSecretLocal = "employment1234";
export class CommonServices {
  
  static ordinal_suffix_of = (num) => {
    let i=num  
       try {  
            let j = i % 10,
                k = i % 100;
            if (j === 1 && k !== 11) {
                return i + "st";
            }
            if (j === 2 && k !== 12) {
                return i + "nd";
            }
            if (j === 3 && k !== 13) {
                return i + "rd";
            }
            return i + "th"; 
      } catch (error) {
        console.log(error,"decryptedData   error")
       } 
       return i
     }; 

  static getExtension = (num) => {
    let res=""  
    let regd=/(?:\.([^.]+))?$/
       try { 
        if(num?.length){ 
          res= num?.split('.').pop();
      //   res= regd.exec(num) 

         }
      } catch (error) {
        // console.log(error,"decryptedData   error")
       } 
       return res
     }; 
     
  static confirmPopUp = (title, message) => {
    let res = false;
    // Decrypt
    try {
      return new Promise(function (myResolve) {
        confirmAlert({
          title: title || "Confirm to submit",
          message: message || "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                myResolve(true);
              },
            },
            {
              label: "No",
              onClick: () => {
                myResolve(false);
              },
            },
          ],
        });
      });
    } catch (error) {
      return res;
    }
  };

  
  static getPostAttachments = (data) => {
    const {attachments,thumbnail,firstUrl}=data
    let resp = firstUrl?null:[];
    let urlsd = [];
    try {
      attachments.forEach((element) => {
        let imageD=element?.url
        if(element?.Photo){
          if(element?.Photo?.fullPictureURL){
            imageD=element?.Photo?.fullPictureURL
          }
          let size=null
          element?.Photo?.Images?.forEach((image,index) => {
            if(index==0){
              size=image?.width*image?.height
              imageD=image?.fullPath
            }
            if(thumbnail&&(size>image?.width*image?.height)){
              size=image?.width*image?.height
              if(image?.baseUrl&&image?.imagePath){
                  image= new URL(image?.imagePath, image?.baseUrl).href;
              }
              else{
                imageD=image?.fullPath 
              }
            }
            else if((!thumbnail)&&(size<(image?.width*image?.height))){
              size=image?.width*image?.height
              imageD=image?.fullPath
            }
          });
        } 
         urlsd.push(imageD); 
      }); 
      if(urlsd?.length>0){
        if(firstUrl){
          resp=urlsd[0]
        }
        else{
          resp=urlsd
        }
      }
    } catch (error) {
      
    }
    return resp;
  };

  
  static getAttachmentsWithHighAndLowQualityImages  = (data) => {
    const {attachments,thumbnail,firstUrl}=data
    let resp =[]; 
    try {
      attachments.forEach((element) => {
        let attachmentD={
          data:element,
          image:{}
        }
        if(element?.Photo?.Images){
          let imageD={
            highQualityImage:null,
            thumbnailImage:null,
            lowQualityImage:null, 
          }
          imageD.images=element?.Photo?.Images
          let hignQ=this.getHighQualityAttachmentImage_basedOnNetworkPerformance({images:element?.Photo?.Images})
          let lowQ=this.getLowQualityAttachmentImage({images:element?.Photo?.Images})
          if(hignQ){  
            imageD.highQualityImage=hignQ
          } 
          if(lowQ){  
            imageD.lowQualityImage=lowQ
            imageD.thumbnailImage=lowQ
          } 
          attachmentD.image=imageD
        }
       resp.push(attachmentD); 
      });  
    } catch (error) {
      
    }
    // console.log("getAttachmentsWithHighAndLowQualityImages",resp)
    return resp;
  };
  
  static getLowQualityAttachmentImage = (data) => {
    const {images}=data 
    let resp =  null  
    try { 
      let imageD=null 
      let size=null
      images?.forEach((image,index) => {
        if(index==0){
          size=image?.width*image?.height
          imageD=image 
        }
        if((size>image?.width*image?.height)){
          size=image?.width*image?.height
          imageD=image  
        } 
      }); 
      resp = imageD    
    } catch (error) {
      
    }
    return resp;
  };

  static getHighQualityAttachmentImage_basedOnNetworkPerformance = (data) => {
    const {images }=data 
    let resp =  null  
    try { 
        let imageD=null 
        let size=null
        images?.forEach((image,index) => {
          if(index==0){
            size=image?.width*image?.height
            imageD=image
          }
          if(size<(image?.width*image?.height)){
            size=image?.width*image?.height
            imageD=image  
          } 
        }); 
        resp = imageD    
    } catch (error) {
      
    }
    return resp;
  };

  

  static UrlExists= async(url)=>
  {
      var http =  new XMLHttpRequest();
     await http.open('HEAD', url, false);
      http.send();
      return http.status!=404;
  }

  static decryptLocalData = (data) => {
    let decryptedData = false;
    console.log(typeof data, data, "decryptedData   data");
    try {
      if (typeof data == "string") {
        decryptedData = this.decrypt(data, encryptionSecretLocal);
      }
    } catch (error) {
      console.log(error, "decryptedData   error");
    }
    return decryptedData;
  };

  static encryptLocalData = (data) => {
    let response = {
      success: false,
      message: "No response, please contact your service provider",
      record: null,
    };
    try {
      response = this.encrypt(data, encryptionSecretLocal);
    } catch (err) {}
    return response;
  };

  static encrypt(word, secretKey) {
    let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), secretKey).toString();
    let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
    return encData;
  }

  static decrypt(word, secretKey) {
    let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
    let bytes = CryptoJS.AES.decrypt(decData, secretKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  }

  static getDataFromArray(dataArray, searchField, searchValue, returnRows, returnField) {
    let res = returnField ? "" : {};
    // console.log("dataArray   ", dataArray,searchField, searchValue, returnRows, returnField);
    try {
      if (dataArray && searchField && searchValue) {
        if (dataArray.length > 0) {
          let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
          if (filteredLevels.length) {
            res = filteredLevels;
            if (returnRows == "single") {
              let row = filteredLevels[0];
              res = row;
              if (returnField) {
                let rowField = "";
                if (row[returnField]) {
                  rowField = row[returnField];
                }
                res = rowField;
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    return res;
  }

  static getProfileName = (data) => {
    let profileName = "";
    if (data) {
      if (data.profileName) {
        profileName = data.profileName;
      } else if (data.firstName) {
        profileName = data.firstName + " " + data.lastName;
      }
    }
    return profileName;
  };

  static parseText = (text) => {
    if(text){
    return parse(text);
    }
    return text;
  };

  static parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  static checkFriendship = (profileId,freindships ) => {
    let friendship = "";
    let follower = false;
    let following = false;
    let followers = false;
    let followings = false;
    try {
      
    if(freindships){
      if(freindships.followers){
        followers=   freindships.followers
      }
      if(freindships.followings){
        followings =   freindships.followings
      }
     }
    if (profileId) {
      if (followers) {
        if (followers.length) {
          for (let index = 0; index < followers.length; index++) {
        if (followers[index]) {
            const element = followers[index];
            if (element.id == profileId) {
              follower = true;
              break;
            }
          }
          }
        }
      }
      if (followings) {
        if (followings.length) {
          for (let index = 0; index < followings.length; index++) {
            if (followings[index]) {
                const element = followings[index];
                if (element.id == profileId) {
                  following = true;
                  break;
                }
            }
          }
        }
      }
    }
    if (follower && following) {
      friendship = "friend";
    } else if (follower) {
      friendship = "follower";
    } else if (following) {
      friendship = "following";
    }
    } catch (error) {
      console.log("checkFriendship error",error)
    }

    return friendship;
  };

  static amIFollowing = (profileId,followings) => {
    let friendship = "";
    let follower = false;
    let following = false;
    if (profileId) { 
      if (followings) {
        if (followings.length) {
          for (let index = 0; index < followings.length; index++) {
            if (followings[index]) {
              if (followings[index].Profile) {
                const element = followings[index].Profile;
                if (element.id == profileId) {
                  following = true;
                  break;
                }
              }
            }
          }
        }
      }
    }
    if (follower && following) {
      friendship = "friend";
    } else if (follower) {
      friendship = "follower";
    } else if (following) {
      friendship = "following";
    }

    return friendship;
  };

  static truncateStringDefualt = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let txt = str.slice(0, num) + "...";
          res = txt;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  static getDomainFromOrigin(domainNameOrigin) {
    let resp = "";
    let domainName = domainNameOrigin;
    if (domainName) {
      if (domainName.length) {
        domainName = domainName.toLowerCase();
        // domainName="https://www.mmmm2.gedeopeople2.gov.et/"
        let exdomainName = domainName.replace("//www.", "//");
        exdomainName = new URL(exdomainName);
        if (exdomainName) {
          if (exdomainName.hostname) {
            resp = exdomainName.hostname;
          }
        }
      }
    }
    return resp;
  }
}
