
import Grid from "@mui/material/Grid"; 
import { isMobile } from "react-device-detect";

import parse from "html-react-parser";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
// Images
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import React, { useEffect } from "react";
import {  ContactMail, Send, Work } from "@mui/icons-material";
import { 
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider, 
} from "@mui/material";


function JobPostDetails(props) {
  const [currentData, updateContext] = useCurrentUser();
  const {
    employmentTypes, 
    employmentSalaryDealTypes,
    myJobApplications,
    myCurrentProfile,
    agencyData,
    isAgentWebsite,
    isUserLoggedIn,
  } = currentData; 
  const [forceRerender, setForceRerender] = React.useState(false);
  const navigateComp = useNavigate();
  const [openedDialog, setOpenedDialog] = React.useState("");
  const [moreText, setMoreText] = React.useState({ content: "", title: "" });
  const [post, setPost] = React.useState(null);
  const [employmentJobVacancy, setEmploymentJobVacancy] = React.useState(null);

  useEffect(() => {
     if(props?.employmentJobVacancy){
      setEmploymentJobVacancy(props?.employmentJobVacancy)
      }
     if(props?.post){
      setPost(props?.post)
      }
    //  console.log("  jobVacancy short",employmentJobVacancy)
  },[props?.employmentJobVacancy]);
  
  const truncateString = (str, num, lableName) => {
    let res = str;
    try {
      if (str) {
        if (str.length > num) {
          let btn = (
            <Button
              variant="text"
              onClick={() => {
                setMoreText({ content: str, title: lableName });
                setOpenedDialog("moreText");
                setForceRerender(!forceRerender);
              }}
            >
              {"View more"}
            </Button>
          );
          let txt = str.slice(0, num) + "...";
          res = (
            <>
              {parse(txt)} {btn}
            </>
          );
        } else {
          res = <>{parse(res)}</>;
        }
      }
    } catch (error) {
      // console.log("errrr",error)
    }
    return res;
  };

  const getDataFromArray = (dataArray, searchField, searchValue, returnRows, returnField) => {
    let res = returnField ? "" : {};

    try {
      if (searchValue) {
        if (dataArray && searchField && searchValue) {
          if (dataArray.length > 0) {
            let filteredLevels = dataArray.filter((level) => level[searchField] == searchValue);
            if (filteredLevels.length) {
              res = filteredLevels;
              if (returnRows == "single") {
                let row = filteredLevels[0];
                res = row;
                if (returnField) {
                  let rowField = row[returnField];
                  res = rowField;
                }
              }
            }
          }
        }
      }
      // console.log("filtered city  ", res);
    } catch (error) {
      // console.log("filtered city error", error);
    }
    // console.log("filtered city  ", res);
    return res;
  };

  const isJobAlreadyApplied = (id) => {
    let res = false;

    if (myJobApplications && id) {
      if (myJobApplications.length) {
        for (let i = 0; i < myJobApplications.length; i++) {
          if (myJobApplications[i].EmploymentJobVacancyId == id) {
            return true;
          }
        }
      }
    }
    return res;
  };
 
 
 
  const editInputComponent = (
    parentFieldName,
    fieldName,
    fieldLabel,
    valueD,
    fieldType,
    options,
    optionsSelectedField,
    optionsSelectedLable
  ) => {
    // const editInputComponent = (fieldName, fieldLabel, valueD, fieldType, options, selectedField) => {
    // console.log("fieldName", valueD);
    let res = <></>;
    let inputComp = <></>;
    let value = valueD;
    try {
      let accordionTitle = "-";
      if (fieldType == "fileUrl") {
        accordionTitle = (
          <Button
            href={value}
            target="_blank"
            onClick={() => {
              // setDocumentUrl(value);
              // setOpenedDialog("documentviewer")
            }}
            color="primary"
            round
            size="sm"
          >
            open file
          </Button>
        );
      } else if (fieldType == "chip" || fieldType == "chipText") {
        let chipsData = []; //value
        if (value) {
          if (typeof value == "string") {
            chipsData = value.split(",");
          } else if (value.length) {
            chipsData = value;
          }
        }
        accordionTitle = (
          <>
            <>
              {chipsData.map((chipValue) => (
                <Chip
                  label={optionsSelectedLable ? chipValue[optionsSelectedLable] : chipValue}
                  variant="outlined"
                />
              ))}
            </>
          </>
        );
      } else {
        if (value) {
          accordionTitle = optionsSelectedLable
            ? value[optionsSelectedLable]
              ? value[optionsSelectedLable]
              : ""
            : value;
        }
      }

      if (typeof accordionTitle == "string") {
        accordionTitle = truncateString(accordionTitle, 50);
      }
      res = (
        <>
          {/* <Accordion expanded={expanded === fieldName} onChange={handleChange(fieldName)}> */}
          <Accordion expanded={false}>
            <AccordionSummary
              // expandIcon={<Edit />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              {/* <MKTypography sx={{ width: "43%", flexShrink: 0 }} variant="body2" color="text" fontWeight="regular" pl={1}>
              {fieldLabel}  </MKTypography>
              <MKTypography sx={{ color: "text.secondary" }} variant="body2" color="text" fontWeight="regular" pl={1}>
              <strong>{accordionTitle}</strong> </MKTypography> */}

              <MKTypography
                sx={{ width: "43%", flexShrink: 0 }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {fieldLabel} {isMobile ? " : " : ""}
              </MKTypography>
              <MKTypography
                sx={{ color: "text.secondary" }}
                variant="body2"
                color="text"
                fontWeight="regular"
                pl={1}
              >
                {" "}
                <strong> {accordionTitle}</strong>
              </MKTypography>
            </AccordionSummary>
          </Accordion>
        </>
      );
    } catch (error) {
      // console.log("inp comp", error);
    }
    return res;
  };

  const parseDate = (dt) => {
    let d = new Date(dt);
    let m = d.getMonth() + 1;
    let dateD = m + "/" + d.getDate() + "/" + d.getFullYear();
    return dateD;
  };

  return (
    <>
     
        {employmentJobVacancy && (
          // <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              {employmentJobVacancy && (
                <Grid container justifyContent="left" >
                  <Grid item xs={(post ? (post?.Profile?true:false) : false)  ?  9 :  12}>
                    {/* <MKBox justifyContent="space-between"   mb={1}> */}
                    <MKTypography variant={isMobile?"h6":"h4"}>{employmentJobVacancy.title}</MKTypography>

                    {/* </MKBox> */}
                  </Grid> 
                </Grid>
              )}
  

              {employmentJobVacancy && (
                <Grid  item xs={12} py={2} justifyContent="center"> 
                        <MKBox py={3} >
                          {/* <MKTypography variant="h3" mb={1}>
                          Personal Informations
                        </MKTypography> */}

                          <MKTypography
                            sx={{ color: "text.secondary" }}
                            variant="h5"
                            color="text"
                            fontWeight="regular"
                            pl={1}
                          >
                            <strong> Basic Information  </strong>
                          </MKTypography>
                        </MKBox>

                        <MKBox>
                          {editInputComponent(
                            "jobseeker",
                            "employmentTypeId",
                            "Employment Type",
                            getDataFromArray(
                              employmentTypes,
                              "id",
                             props?.post?.EmploymentJobVacancy?.employmentTypeId,
                              "single",
                              "title"
                            ),
                            "textField"
                          )}

                          {editInputComponent(
                            "jobseeker",
                            "salaryDealTypeId",
                            "Salary Amount",

                            (employmentJobVacancy?.salary ?props?.post?.EmploymentJobVacancy?.salary + " " : "") +
                              " " +
                              (employmentJobVacancy.salaryCurrency
                                ?props?.post?.EmploymentJobVacancy.salaryCurrency + " "
                                : "") +
                              " " +
                              (employmentJobVacancy.salaryDuration
                                ? " (" +employmentJobVacancy.salaryDuration + ") "
                                : ""),
                            "textField"
                          )}

                          {editInputComponent(
                            "jobseeker",
                            "salaryDealTypeId",
                            "Salary Deal",
                            getDataFromArray(
                              employmentSalaryDealTypes,
                              "id",
                             props?.post?.EmploymentJobVacancy.salaryDealTypeId,
                              "single",
                              "title"
                            ),
                            "textField"
                          )}

                          {editInputComponent(
                            "jobseeker",
                            "Deadline",
                            "Deadline",
                            parseDate(employmentJobVacancy.deadLineDateTime),
                            "textField"
                          )}

                          <MKTypography mt={5} mb={2} mx={2}>
                            Required
                          </MKTypography>
                          {editInputComponent(
                            "jobseeker",
                            "applicantGender",
                            "Gender",
                           props?.post?.EmploymentJobVacancy.applicantGender,
                            "textField"
                          )}
                          {editInputComponent(
                            "jobseeker",
                            "requiredNumberOfEmployees",
                            "Employees  ",
                           props?.post?.EmploymentJobVacancy.requiredNumberOfEmployees + " employee(s)",
                            "textField"
                          )}

                          {(employmentJobVacancy.minimumExperianceInYear != null ||
                           props?.post?.EmploymentJobVacancy.maximumExperianceInYear != null) && (
                            <>
                              {/* <MKTypography mt={5} mb={2} mx={2}>
                          Experiance
                        </MKTypography> */}
                              {editInputComponent(
                                "jobseeker",
                                "minimumExperianceInYear",
                                "Experiance",
                                (employmentJobVacancy.minimumExperianceInYear != null
                                  ? "starting from " +
                                 props?.post?.EmploymentJobVacancy.minimumExperianceInYear +
                                    " "
                                  : "") +
                                  (employmentJobVacancy.maximumExperianceInYear >
                                   props?.post?.EmploymentJobVacancy.minimumExperianceInYear
                                    ? " to " +props?.post?.EmploymentJobVacancy.maximumExperianceInYear
                                    : "") +
                                  " year(s) ",

                                "textField"
                              )}
                            </>
                          )}
                          <MKTypography mt={5} mb={2} mx={2}>
                            Location
                          </MKTypography>

                          {editInputComponent(
                            "jobseeker",
                            "country",
                            "City",
                           props?.post?.Address?.City?.name,
                            "textField"
                          )} 
                              {props?.post?.Address?.CityId  == 1 && (
                                <>
                                  {editInputComponent(
                                    "jobseeker",
                                    "subCity",
                                    "Specific Area",
                                   props?.post?.Address?.subCity +
                                      " / " +
                                     props?.post?.Address?.specificArea,
                                    "textField"
                                  )}{" "}
                                </>
                              )}
                              {editInputComponent(
                                "jobseeker",
                                "jobLocationTypeId",
                                "Location Type",
                                getDataFromArray(
                                  post?.EmploymentJobLocationTypes,
                                  "id",
                                 props?.post?.EmploymentJobVacancy.jobLocationTypeId,
                                  "single",
                                  "title"
                                ),
                                "textField"
                              )} 
 
             {(props?.post?.EmploymentJobVacancy?.employmentApplicationRequestsCount)? <>
                          <MKTypography mt={5} mb={2} mx={2}>
                            Activities
                          </MKTypography>
                          {editInputComponent(
                            "jobseeker",
                            "country",
                            "Proposals",
                           props?.post?.EmploymentJobVacancy?.employmentApplicationRequestsCount,
                            "textField"
                          )} </>:""}
                        </MKBox>  
                        
               
                </Grid>
              )}

              {employmentJobVacancy && (
                <Grid container item xs={12} py={2} justifyContent="center">
                  {/* <Card sx={{ width: "100%" }}> */}
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={12}>
                        {/* <MKBox py={3} px={2}> 

                          <MKTypography
                            sx={{ color: "text.secondary" }}
                            variant="h5"
                            color="text"
                            fontWeight="regular"
                            // pl={1}
                          >
                            <strong> Job Description</strong>
                          </MKTypography>
                        </MKBox> */}

                        <MKBox >
                          {/* {post?.message && (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                                Overview :
                              </MKTypography>
                              <MKBox pl={2}>{parse(post?.message)}</MKBox>
                            </MKBox>
                          )} */}

                          <Divider />
                          {employmentJobVacancy.employeeResponsiblity && (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                              Requirement Description :
                              </MKTypography>
                              <MKBox pl={2}>
                                {parse(employmentJobVacancy.employeeResponsiblity)}
                              </MKBox>
                            </MKBox>
                          )}

                          {/* {employmentJobVacancy.requiredSkills && (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                                Required Skills :
                              </MKTypography>
                              <MKBox pl={2}>{parse(employmentJobVacancy.requiredSkills)}</MKBox>
                            </MKBox>
                          )} */}

                          {/* {employmentJobVacancy.requiredExperiances? (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                                Required Experiances :
                              </MKTypography>
                              <MKBox pl={2}>
                                {parse(employmentJobVacancy.requiredExperiances)}
                              </MKBox>
                            </MKBox>
                          ):null} */}

                          <Divider />
                          {employmentJobVacancy.allowances ? (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                                Allowances:
                              </MKTypography>
                              <MKBox pl={2}>{parse(employmentJobVacancy.allowances)}</MKBox>
                            </MKBox>
                          ):null}

                          <Divider />
                          {employmentJobVacancy.howToApply? (
                            <MKBox mb={4}>
                              <MKTypography fontWeight="bold" mt={2}>
                                How To Apply:
                              </MKTypography>
                              <MKBox pl={2}>{parse(employmentJobVacancy.howToApply)}</MKBox>
                            </MKBox>
                          ):null}
                           {post?.EmploymentJobVacancy?.applicationLink?<Button
                              component="a"
                              href={employmentJobVacancy?.applicationLink ? employmentJobVacancy?.applicationLink : "#"}
                              target="_blank" // Open in a new tab
                              rel="noopener noreferrer" // Security measure
                              color="primary"
                              size="small"
                              // sx={{ m: 1 }}
                            >
                             External Application Link
                            </Button>:null}
                        </MKBox>
                      </Grid>
                    </Grid>
                  {/* </Card> */}
                </Grid>
              )}
 
 
            </Grid>
          // {/* </Container> */}
        )}

      <Dialog
        open={openedDialog == "moreText"}
        onClose={() => {
          setOpenedDialog("");
        }}
      >
        {moreText && (
          <>
            {moreText.title && <DialogTitle>{parse(moreText.title)}</DialogTitle>}
            {moreText.content && (
              <DialogContent>
                <DialogContentText>{parse(moreText.content)}</DialogContentText>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setOpenedDialog("");
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
 
    </>
  );
}

export default JobPostDetails;
