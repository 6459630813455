/* 
*/

// @mui material components
import Grid from "@mui/material/Grid";

import { DropzoneArea } from "mui-file-dropzone";
import parse from 'html-react-parser'; 

// Routes 
import { useEffect, useState } from "react";
import { HttpService } from "Api-Services/httpService";
import { useCurrentUser } from "Api-contexts/currentUserContext";
import { Button } from "@mui/material";
import axios from "axios";
import "./fileUploadComp.css"
import _ from "lodash"; 

// .css-1agvk75 {
//   position: relative;
//   width: 100%;
//   min-height: 250px;
//   background-color: #fff;
//   border: dashed;

function FileUploadComp(props) { 
   
  const [filesArrayOnDropZone,setFilesArrayOnDropZone]=useState([]) 
 

  ////////////
 
	const [forceRenderer, setForceRenderer] = useState(false)  

  
  const [currentContextData, updateContext  ] = useCurrentUser();   
	const [selectedFolderPath, setSelectedFolderPath] = useState("")  
  const [uploadedFiles, setuploadedFiles] = useState([]);
  const [dropzoneTempImages, setDropzoneTempImages] = useState([]);
  const [dropzoneChange, setdropzoneChange] = useState(false);
  const [message, setmessage] = useState("");
  const [uploading, setuploading] = useState(false);
  const [error, seterror] = useState(false);
  const [uploadPercentage, setuploadPercentage] = useState(0);
  const [filesLimit, setfilesLimit] = useState(20);
  const [dropZoneFilesLimit, setDropZoneFilesLimit] = useState(20);
   



  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{    
    // await getDocumentsAll("content","/content?limit=20&mode=EXTENDED",{})//init user offices and data   
    // await getDocumentsAll("getCatagories","/categories?limit=1000",{},"api3")//init user offices and data   
    // await postDocuments("search","/search/question?question=how+do+i+upgrade+my+device", {"transactionId":0},"api4")//init user offices and data    
  }
  
const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
 

const handleSave=async (SrNumber) =>{
  // console.log("filesArrayOnDropZone",filesArrayOnDropZone) 
  if(filesArrayOnDropZone&&SrNumber){
    let filesArray=filesArrayOnDropZone
    for(let ff=0;ff<filesArray.length;ff++){

      // let respp=await sendAttachment("file",filesArray[ff],SrNumber) 
      // console.log("sendAttachment respp",respp)
      } 
  }
}

const handleChange=async (filesData) =>{
  setDropzoneTempImages(filesData)
  props.handleDropZoneFiles(filesData)
  setuploading(false)
  seterror(false) 
/////////////////////////

    //  try{
    //   console.log("handleChange filesData",filesData)
    //   var filesArrayOnDropZoneD=[];  
    //   for(let f=0;f<filesData.length;f++){
    //   // let file  = 
    //   var fileD =  filesData[f];   // FileList object 
    //   var reader = new FileReader(); 
    //   reader.onload =   (function(theFile) {
    //     return function(e) {
    //       var binaryData = e.target.result; 
    //       var base64String = window.btoa(binaryData);   
    //       filesArrayOnDropZoneD.push({
    //         CategoryName: "MISC",
    //         DatatypeCode: "FILE",
    //         Description: "",
    //         FileContents:base64String ,//,attachmentFile//"UEsDBBQABgAIAAAAIQDCo+7qtQEAADYJAAATAAgCW0NvbnRlb
    //         FileName: filesData[f].name,
    //         UploadedFileContentType:filesData[f].type //"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //"//"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //     })
    //     // console.log("filesArrayOnDropZoneD reqqq",filesArrayOnDropZoneD)
    //     setFilesArrayOnDropZone(filesArrayOnDropZoneD)
    //     };
    //   })(fileD); 
    //   reader.readAsBinaryString(fileD); 
    //   }  
              
    // }
    // catch(err){}
    }
   
 
 
   


  return (
    <> 
 

                  <DropzoneArea 
                  // {maxHeight: "17px",overflow: "hidden"}} 
                      dropzoneClass={"dropzoneClass"}
                      showPreviewsInDropzone={false}
                      showPreviews={true}
                      dropzoneText="Click here to add attachment"
                      acceptedFiles={props.acceptedFiles?props.acceptedFiles:[]}
                      filesLimit={props.filesLimit?props.filesLimit:10}
                      initialFiles={props.initialFiles?props.initialFiles:[]}
                      useChipsForPreview
                      previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                      previewChipProps={{classes: { root: {   minWidth: 160,   maxWidth: 210}} }}
                      previewText="Selected files"
                      onSave={(e)=>{
                        handleSave(e) 
                      }}
                   onChange={(e)=>{handleChange(e)}}
                        /> 



      
    </>
  );
}

export default FileUploadComp;
